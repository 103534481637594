@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
.btn-outline-danger:hover,
.btn-outline-primary:hover {
  color: white !important;
}

.form-control-lg {
  padding: 0.75rem 1.25rem !important;
}

.toast-container {
  z-index: 3000 !important;
}

.toast {
  position: relative;
  z-index: 3000;
}

/*!
 * Bootstrap Icons v1.11.3 (https://icons.getbootstrap.com/)
 * Copyright 2019-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/icons/blob/main/LICENSE)
 */
@font-face {
  font-display: block;
  font-family: "bootstrap-icons";
  src: url("./fonts/bootstrap-icons.woff2?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff2"), url("./fonts/bootstrap-icons.woff?24e3eb84d0bcaf83d77f904c78ac1f47") format("woff");
}
.bi::before,
[class^=bi-]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: "bootstrap-icons" !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -0.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-123::before {
  content: "\f67f";
}

.bi-alarm-fill::before {
  content: "\f101";
}

.bi-alarm::before {
  content: "\f102";
}

.bi-align-bottom::before {
  content: "\f103";
}

.bi-align-center::before {
  content: "\f104";
}

.bi-align-end::before {
  content: "\f105";
}

.bi-align-middle::before {
  content: "\f106";
}

.bi-align-start::before {
  content: "\f107";
}

.bi-align-top::before {
  content: "\f108";
}

.bi-alt::before {
  content: "\f109";
}

.bi-app-indicator::before {
  content: "\f10a";
}

.bi-app::before {
  content: "\f10b";
}

.bi-archive-fill::before {
  content: "\f10c";
}

.bi-archive::before {
  content: "\f10d";
}

.bi-arrow-90deg-down::before {
  content: "\f10e";
}

.bi-arrow-90deg-left::before {
  content: "\f10f";
}

.bi-arrow-90deg-right::before {
  content: "\f110";
}

.bi-arrow-90deg-up::before {
  content: "\f111";
}

.bi-arrow-bar-down::before {
  content: "\f112";
}

.bi-arrow-bar-left::before {
  content: "\f113";
}

.bi-arrow-bar-right::before {
  content: "\f114";
}

.bi-arrow-bar-up::before {
  content: "\f115";
}

.bi-arrow-clockwise::before {
  content: "\f116";
}

.bi-arrow-counterclockwise::before {
  content: "\f117";
}

.bi-arrow-down-circle-fill::before {
  content: "\f118";
}

.bi-arrow-down-circle::before {
  content: "\f119";
}

.bi-arrow-down-left-circle-fill::before {
  content: "\f11a";
}

.bi-arrow-down-left-circle::before {
  content: "\f11b";
}

.bi-arrow-down-left-square-fill::before {
  content: "\f11c";
}

.bi-arrow-down-left-square::before {
  content: "\f11d";
}

.bi-arrow-down-left::before {
  content: "\f11e";
}

.bi-arrow-down-right-circle-fill::before {
  content: "\f11f";
}

.bi-arrow-down-right-circle::before {
  content: "\f120";
}

.bi-arrow-down-right-square-fill::before {
  content: "\f121";
}

.bi-arrow-down-right-square::before {
  content: "\f122";
}

.bi-arrow-down-right::before {
  content: "\f123";
}

.bi-arrow-down-short::before {
  content: "\f124";
}

.bi-arrow-down-square-fill::before {
  content: "\f125";
}

.bi-arrow-down-square::before {
  content: "\f126";
}

.bi-arrow-down-up::before {
  content: "\f127";
}

.bi-arrow-down::before {
  content: "\f128";
}

.bi-arrow-left-circle-fill::before {
  content: "\f129";
}

.bi-arrow-left-circle::before {
  content: "\f12a";
}

.bi-arrow-left-right::before {
  content: "\f12b";
}

.bi-arrow-left-short::before {
  content: "\f12c";
}

.bi-arrow-left-square-fill::before {
  content: "\f12d";
}

.bi-arrow-left-square::before {
  content: "\f12e";
}

.bi-arrow-left::before {
  content: "\f12f";
}

.bi-arrow-repeat::before {
  content: "\f130";
}

.bi-arrow-return-left::before {
  content: "\f131";
}

.bi-arrow-return-right::before {
  content: "\f132";
}

.bi-arrow-right-circle-fill::before {
  content: "\f133";
}

.bi-arrow-right-circle::before {
  content: "\f134";
}

.bi-arrow-right-short::before {
  content: "\f135";
}

.bi-arrow-right-square-fill::before {
  content: "\f136";
}

.bi-arrow-right-square::before {
  content: "\f137";
}

.bi-arrow-right::before {
  content: "\f138";
}

.bi-arrow-up-circle-fill::before {
  content: "\f139";
}

.bi-arrow-up-circle::before {
  content: "\f13a";
}

.bi-arrow-up-left-circle-fill::before {
  content: "\f13b";
}

.bi-arrow-up-left-circle::before {
  content: "\f13c";
}

.bi-arrow-up-left-square-fill::before {
  content: "\f13d";
}

.bi-arrow-up-left-square::before {
  content: "\f13e";
}

.bi-arrow-up-left::before {
  content: "\f13f";
}

.bi-arrow-up-right-circle-fill::before {
  content: "\f140";
}

.bi-arrow-up-right-circle::before {
  content: "\f141";
}

.bi-arrow-up-right-square-fill::before {
  content: "\f142";
}

.bi-arrow-up-right-square::before {
  content: "\f143";
}

.bi-arrow-up-right::before {
  content: "\f144";
}

.bi-arrow-up-short::before {
  content: "\f145";
}

.bi-arrow-up-square-fill::before {
  content: "\f146";
}

.bi-arrow-up-square::before {
  content: "\f147";
}

.bi-arrow-up::before {
  content: "\f148";
}

.bi-arrows-angle-contract::before {
  content: "\f149";
}

.bi-arrows-angle-expand::before {
  content: "\f14a";
}

.bi-arrows-collapse::before {
  content: "\f14b";
}

.bi-arrows-expand::before {
  content: "\f14c";
}

.bi-arrows-fullscreen::before {
  content: "\f14d";
}

.bi-arrows-move::before {
  content: "\f14e";
}

.bi-aspect-ratio-fill::before {
  content: "\f14f";
}

.bi-aspect-ratio::before {
  content: "\f150";
}

.bi-asterisk::before {
  content: "\f151";
}

.bi-at::before {
  content: "\f152";
}

.bi-award-fill::before {
  content: "\f153";
}

.bi-award::before {
  content: "\f154";
}

.bi-back::before {
  content: "\f155";
}

.bi-backspace-fill::before {
  content: "\f156";
}

.bi-backspace-reverse-fill::before {
  content: "\f157";
}

.bi-backspace-reverse::before {
  content: "\f158";
}

.bi-backspace::before {
  content: "\f159";
}

.bi-badge-3d-fill::before {
  content: "\f15a";
}

.bi-badge-3d::before {
  content: "\f15b";
}

.bi-badge-4k-fill::before {
  content: "\f15c";
}

.bi-badge-4k::before {
  content: "\f15d";
}

.bi-badge-8k-fill::before {
  content: "\f15e";
}

.bi-badge-8k::before {
  content: "\f15f";
}

.bi-badge-ad-fill::before {
  content: "\f160";
}

.bi-badge-ad::before {
  content: "\f161";
}

.bi-badge-ar-fill::before {
  content: "\f162";
}

.bi-badge-ar::before {
  content: "\f163";
}

.bi-badge-cc-fill::before {
  content: "\f164";
}

.bi-badge-cc::before {
  content: "\f165";
}

.bi-badge-hd-fill::before {
  content: "\f166";
}

.bi-badge-hd::before {
  content: "\f167";
}

.bi-badge-tm-fill::before {
  content: "\f168";
}

.bi-badge-tm::before {
  content: "\f169";
}

.bi-badge-vo-fill::before {
  content: "\f16a";
}

.bi-badge-vo::before {
  content: "\f16b";
}

.bi-badge-vr-fill::before {
  content: "\f16c";
}

.bi-badge-vr::before {
  content: "\f16d";
}

.bi-badge-wc-fill::before {
  content: "\f16e";
}

.bi-badge-wc::before {
  content: "\f16f";
}

.bi-bag-check-fill::before {
  content: "\f170";
}

.bi-bag-check::before {
  content: "\f171";
}

.bi-bag-dash-fill::before {
  content: "\f172";
}

.bi-bag-dash::before {
  content: "\f173";
}

.bi-bag-fill::before {
  content: "\f174";
}

.bi-bag-plus-fill::before {
  content: "\f175";
}

.bi-bag-plus::before {
  content: "\f176";
}

.bi-bag-x-fill::before {
  content: "\f177";
}

.bi-bag-x::before {
  content: "\f178";
}

.bi-bag::before {
  content: "\f179";
}

.bi-bar-chart-fill::before {
  content: "\f17a";
}

.bi-bar-chart-line-fill::before {
  content: "\f17b";
}

.bi-bar-chart-line::before {
  content: "\f17c";
}

.bi-bar-chart-steps::before {
  content: "\f17d";
}

.bi-bar-chart::before {
  content: "\f17e";
}

.bi-basket-fill::before {
  content: "\f17f";
}

.bi-basket::before {
  content: "\f180";
}

.bi-basket2-fill::before {
  content: "\f181";
}

.bi-basket2::before {
  content: "\f182";
}

.bi-basket3-fill::before {
  content: "\f183";
}

.bi-basket3::before {
  content: "\f184";
}

.bi-battery-charging::before {
  content: "\f185";
}

.bi-battery-full::before {
  content: "\f186";
}

.bi-battery-half::before {
  content: "\f187";
}

.bi-battery::before {
  content: "\f188";
}

.bi-bell-fill::before {
  content: "\f189";
}

.bi-bell::before {
  content: "\f18a";
}

.bi-bezier::before {
  content: "\f18b";
}

.bi-bezier2::before {
  content: "\f18c";
}

.bi-bicycle::before {
  content: "\f18d";
}

.bi-binoculars-fill::before {
  content: "\f18e";
}

.bi-binoculars::before {
  content: "\f18f";
}

.bi-blockquote-left::before {
  content: "\f190";
}

.bi-blockquote-right::before {
  content: "\f191";
}

.bi-book-fill::before {
  content: "\f192";
}

.bi-book-half::before {
  content: "\f193";
}

.bi-book::before {
  content: "\f194";
}

.bi-bookmark-check-fill::before {
  content: "\f195";
}

.bi-bookmark-check::before {
  content: "\f196";
}

.bi-bookmark-dash-fill::before {
  content: "\f197";
}

.bi-bookmark-dash::before {
  content: "\f198";
}

.bi-bookmark-fill::before {
  content: "\f199";
}

.bi-bookmark-heart-fill::before {
  content: "\f19a";
}

.bi-bookmark-heart::before {
  content: "\f19b";
}

.bi-bookmark-plus-fill::before {
  content: "\f19c";
}

.bi-bookmark-plus::before {
  content: "\f19d";
}

.bi-bookmark-star-fill::before {
  content: "\f19e";
}

.bi-bookmark-star::before {
  content: "\f19f";
}

.bi-bookmark-x-fill::before {
  content: "\f1a0";
}

.bi-bookmark-x::before {
  content: "\f1a1";
}

.bi-bookmark::before {
  content: "\f1a2";
}

.bi-bookmarks-fill::before {
  content: "\f1a3";
}

.bi-bookmarks::before {
  content: "\f1a4";
}

.bi-bookshelf::before {
  content: "\f1a5";
}

.bi-bootstrap-fill::before {
  content: "\f1a6";
}

.bi-bootstrap-reboot::before {
  content: "\f1a7";
}

.bi-bootstrap::before {
  content: "\f1a8";
}

.bi-border-all::before {
  content: "\f1a9";
}

.bi-border-bottom::before {
  content: "\f1aa";
}

.bi-border-center::before {
  content: "\f1ab";
}

.bi-border-inner::before {
  content: "\f1ac";
}

.bi-border-left::before {
  content: "\f1ad";
}

.bi-border-middle::before {
  content: "\f1ae";
}

.bi-border-outer::before {
  content: "\f1af";
}

.bi-border-right::before {
  content: "\f1b0";
}

.bi-border-style::before {
  content: "\f1b1";
}

.bi-border-top::before {
  content: "\f1b2";
}

.bi-border-width::before {
  content: "\f1b3";
}

.bi-border::before {
  content: "\f1b4";
}

.bi-bounding-box-circles::before {
  content: "\f1b5";
}

.bi-bounding-box::before {
  content: "\f1b6";
}

.bi-box-arrow-down-left::before {
  content: "\f1b7";
}

.bi-box-arrow-down-right::before {
  content: "\f1b8";
}

.bi-box-arrow-down::before {
  content: "\f1b9";
}

.bi-box-arrow-in-down-left::before {
  content: "\f1ba";
}

.bi-box-arrow-in-down-right::before {
  content: "\f1bb";
}

.bi-box-arrow-in-down::before {
  content: "\f1bc";
}

.bi-box-arrow-in-left::before {
  content: "\f1bd";
}

.bi-box-arrow-in-right::before {
  content: "\f1be";
}

.bi-box-arrow-in-up-left::before {
  content: "\f1bf";
}

.bi-box-arrow-in-up-right::before {
  content: "\f1c0";
}

.bi-box-arrow-in-up::before {
  content: "\f1c1";
}

.bi-box-arrow-left::before {
  content: "\f1c2";
}

.bi-box-arrow-right::before {
  content: "\f1c3";
}

.bi-box-arrow-up-left::before {
  content: "\f1c4";
}

.bi-box-arrow-up-right::before {
  content: "\f1c5";
}

.bi-box-arrow-up::before {
  content: "\f1c6";
}

.bi-box-seam::before {
  content: "\f1c7";
}

.bi-box::before {
  content: "\f1c8";
}

.bi-braces::before {
  content: "\f1c9";
}

.bi-bricks::before {
  content: "\f1ca";
}

.bi-briefcase-fill::before {
  content: "\f1cb";
}

.bi-briefcase::before {
  content: "\f1cc";
}

.bi-brightness-alt-high-fill::before {
  content: "\f1cd";
}

.bi-brightness-alt-high::before {
  content: "\f1ce";
}

.bi-brightness-alt-low-fill::before {
  content: "\f1cf";
}

.bi-brightness-alt-low::before {
  content: "\f1d0";
}

.bi-brightness-high-fill::before {
  content: "\f1d1";
}

.bi-brightness-high::before {
  content: "\f1d2";
}

.bi-brightness-low-fill::before {
  content: "\f1d3";
}

.bi-brightness-low::before {
  content: "\f1d4";
}

.bi-broadcast-pin::before {
  content: "\f1d5";
}

.bi-broadcast::before {
  content: "\f1d6";
}

.bi-brush-fill::before {
  content: "\f1d7";
}

.bi-brush::before {
  content: "\f1d8";
}

.bi-bucket-fill::before {
  content: "\f1d9";
}

.bi-bucket::before {
  content: "\f1da";
}

.bi-bug-fill::before {
  content: "\f1db";
}

.bi-bug::before {
  content: "\f1dc";
}

.bi-building::before {
  content: "\f1dd";
}

.bi-bullseye::before {
  content: "\f1de";
}

.bi-calculator-fill::before {
  content: "\f1df";
}

.bi-calculator::before {
  content: "\f1e0";
}

.bi-calendar-check-fill::before {
  content: "\f1e1";
}

.bi-calendar-check::before {
  content: "\f1e2";
}

.bi-calendar-date-fill::before {
  content: "\f1e3";
}

.bi-calendar-date::before {
  content: "\f1e4";
}

.bi-calendar-day-fill::before {
  content: "\f1e5";
}

.bi-calendar-day::before {
  content: "\f1e6";
}

.bi-calendar-event-fill::before {
  content: "\f1e7";
}

.bi-calendar-event::before {
  content: "\f1e8";
}

.bi-calendar-fill::before {
  content: "\f1e9";
}

.bi-calendar-minus-fill::before {
  content: "\f1ea";
}

.bi-calendar-minus::before {
  content: "\f1eb";
}

.bi-calendar-month-fill::before {
  content: "\f1ec";
}

.bi-calendar-month::before {
  content: "\f1ed";
}

.bi-calendar-plus-fill::before {
  content: "\f1ee";
}

.bi-calendar-plus::before {
  content: "\f1ef";
}

.bi-calendar-range-fill::before {
  content: "\f1f0";
}

.bi-calendar-range::before {
  content: "\f1f1";
}

.bi-calendar-week-fill::before {
  content: "\f1f2";
}

.bi-calendar-week::before {
  content: "\f1f3";
}

.bi-calendar-x-fill::before {
  content: "\f1f4";
}

.bi-calendar-x::before {
  content: "\f1f5";
}

.bi-calendar::before {
  content: "\f1f6";
}

.bi-calendar2-check-fill::before {
  content: "\f1f7";
}

.bi-calendar2-check::before {
  content: "\f1f8";
}

.bi-calendar2-date-fill::before {
  content: "\f1f9";
}

.bi-calendar2-date::before {
  content: "\f1fa";
}

.bi-calendar2-day-fill::before {
  content: "\f1fb";
}

.bi-calendar2-day::before {
  content: "\f1fc";
}

.bi-calendar2-event-fill::before {
  content: "\f1fd";
}

.bi-calendar2-event::before {
  content: "\f1fe";
}

.bi-calendar2-fill::before {
  content: "\f1ff";
}

.bi-calendar2-minus-fill::before {
  content: "\f200";
}

.bi-calendar2-minus::before {
  content: "\f201";
}

.bi-calendar2-month-fill::before {
  content: "\f202";
}

.bi-calendar2-month::before {
  content: "\f203";
}

.bi-calendar2-plus-fill::before {
  content: "\f204";
}

.bi-calendar2-plus::before {
  content: "\f205";
}

.bi-calendar2-range-fill::before {
  content: "\f206";
}

.bi-calendar2-range::before {
  content: "\f207";
}

.bi-calendar2-week-fill::before {
  content: "\f208";
}

.bi-calendar2-week::before {
  content: "\f209";
}

.bi-calendar2-x-fill::before {
  content: "\f20a";
}

.bi-calendar2-x::before {
  content: "\f20b";
}

.bi-calendar2::before {
  content: "\f20c";
}

.bi-calendar3-event-fill::before {
  content: "\f20d";
}

.bi-calendar3-event::before {
  content: "\f20e";
}

.bi-calendar3-fill::before {
  content: "\f20f";
}

.bi-calendar3-range-fill::before {
  content: "\f210";
}

.bi-calendar3-range::before {
  content: "\f211";
}

.bi-calendar3-week-fill::before {
  content: "\f212";
}

.bi-calendar3-week::before {
  content: "\f213";
}

.bi-calendar3::before {
  content: "\f214";
}

.bi-calendar4-event::before {
  content: "\f215";
}

.bi-calendar4-range::before {
  content: "\f216";
}

.bi-calendar4-week::before {
  content: "\f217";
}

.bi-calendar4::before {
  content: "\f218";
}

.bi-camera-fill::before {
  content: "\f219";
}

.bi-camera-reels-fill::before {
  content: "\f21a";
}

.bi-camera-reels::before {
  content: "\f21b";
}

.bi-camera-video-fill::before {
  content: "\f21c";
}

.bi-camera-video-off-fill::before {
  content: "\f21d";
}

.bi-camera-video-off::before {
  content: "\f21e";
}

.bi-camera-video::before {
  content: "\f21f";
}

.bi-camera::before {
  content: "\f220";
}

.bi-camera2::before {
  content: "\f221";
}

.bi-capslock-fill::before {
  content: "\f222";
}

.bi-capslock::before {
  content: "\f223";
}

.bi-card-checklist::before {
  content: "\f224";
}

.bi-card-heading::before {
  content: "\f225";
}

.bi-card-image::before {
  content: "\f226";
}

.bi-card-list::before {
  content: "\f227";
}

.bi-card-text::before {
  content: "\f228";
}

.bi-caret-down-fill::before {
  content: "\f229";
}

.bi-caret-down-square-fill::before {
  content: "\f22a";
}

.bi-caret-down-square::before {
  content: "\f22b";
}

.bi-caret-down::before {
  content: "\f22c";
}

.bi-caret-left-fill::before {
  content: "\f22d";
}

.bi-caret-left-square-fill::before {
  content: "\f22e";
}

.bi-caret-left-square::before {
  content: "\f22f";
}

.bi-caret-left::before {
  content: "\f230";
}

.bi-caret-right-fill::before {
  content: "\f231";
}

.bi-caret-right-square-fill::before {
  content: "\f232";
}

.bi-caret-right-square::before {
  content: "\f233";
}

.bi-caret-right::before {
  content: "\f234";
}

.bi-caret-up-fill::before {
  content: "\f235";
}

.bi-caret-up-square-fill::before {
  content: "\f236";
}

.bi-caret-up-square::before {
  content: "\f237";
}

.bi-caret-up::before {
  content: "\f238";
}

.bi-cart-check-fill::before {
  content: "\f239";
}

.bi-cart-check::before {
  content: "\f23a";
}

.bi-cart-dash-fill::before {
  content: "\f23b";
}

.bi-cart-dash::before {
  content: "\f23c";
}

.bi-cart-fill::before {
  content: "\f23d";
}

.bi-cart-plus-fill::before {
  content: "\f23e";
}

.bi-cart-plus::before {
  content: "\f23f";
}

.bi-cart-x-fill::before {
  content: "\f240";
}

.bi-cart-x::before {
  content: "\f241";
}

.bi-cart::before {
  content: "\f242";
}

.bi-cart2::before {
  content: "\f243";
}

.bi-cart3::before {
  content: "\f244";
}

.bi-cart4::before {
  content: "\f245";
}

.bi-cash-stack::before {
  content: "\f246";
}

.bi-cash::before {
  content: "\f247";
}

.bi-cast::before {
  content: "\f248";
}

.bi-chat-dots-fill::before {
  content: "\f249";
}

.bi-chat-dots::before {
  content: "\f24a";
}

.bi-chat-fill::before {
  content: "\f24b";
}

.bi-chat-left-dots-fill::before {
  content: "\f24c";
}

.bi-chat-left-dots::before {
  content: "\f24d";
}

.bi-chat-left-fill::before {
  content: "\f24e";
}

.bi-chat-left-quote-fill::before {
  content: "\f24f";
}

.bi-chat-left-quote::before {
  content: "\f250";
}

.bi-chat-left-text-fill::before {
  content: "\f251";
}

.bi-chat-left-text::before {
  content: "\f252";
}

.bi-chat-left::before {
  content: "\f253";
}

.bi-chat-quote-fill::before {
  content: "\f254";
}

.bi-chat-quote::before {
  content: "\f255";
}

.bi-chat-right-dots-fill::before {
  content: "\f256";
}

.bi-chat-right-dots::before {
  content: "\f257";
}

.bi-chat-right-fill::before {
  content: "\f258";
}

.bi-chat-right-quote-fill::before {
  content: "\f259";
}

.bi-chat-right-quote::before {
  content: "\f25a";
}

.bi-chat-right-text-fill::before {
  content: "\f25b";
}

.bi-chat-right-text::before {
  content: "\f25c";
}

.bi-chat-right::before {
  content: "\f25d";
}

.bi-chat-square-dots-fill::before {
  content: "\f25e";
}

.bi-chat-square-dots::before {
  content: "\f25f";
}

.bi-chat-square-fill::before {
  content: "\f260";
}

.bi-chat-square-quote-fill::before {
  content: "\f261";
}

.bi-chat-square-quote::before {
  content: "\f262";
}

.bi-chat-square-text-fill::before {
  content: "\f263";
}

.bi-chat-square-text::before {
  content: "\f264";
}

.bi-chat-square::before {
  content: "\f265";
}

.bi-chat-text-fill::before {
  content: "\f266";
}

.bi-chat-text::before {
  content: "\f267";
}

.bi-chat::before {
  content: "\f268";
}

.bi-check-all::before {
  content: "\f269";
}

.bi-check-circle-fill::before {
  content: "\f26a";
}

.bi-check-circle::before {
  content: "\f26b";
}

.bi-check-square-fill::before {
  content: "\f26c";
}

.bi-check-square::before {
  content: "\f26d";
}

.bi-check::before {
  content: "\f26e";
}

.bi-check2-all::before {
  content: "\f26f";
}

.bi-check2-circle::before {
  content: "\f270";
}

.bi-check2-square::before {
  content: "\f271";
}

.bi-check2::before {
  content: "\f272";
}

.bi-chevron-bar-contract::before {
  content: "\f273";
}

.bi-chevron-bar-down::before {
  content: "\f274";
}

.bi-chevron-bar-expand::before {
  content: "\f275";
}

.bi-chevron-bar-left::before {
  content: "\f276";
}

.bi-chevron-bar-right::before {
  content: "\f277";
}

.bi-chevron-bar-up::before {
  content: "\f278";
}

.bi-chevron-compact-down::before {
  content: "\f279";
}

.bi-chevron-compact-left::before {
  content: "\f27a";
}

.bi-chevron-compact-right::before {
  content: "\f27b";
}

.bi-chevron-compact-up::before {
  content: "\f27c";
}

.bi-chevron-contract::before {
  content: "\f27d";
}

.bi-chevron-double-down::before {
  content: "\f27e";
}

.bi-chevron-double-left::before {
  content: "\f27f";
}

.bi-chevron-double-right::before {
  content: "\f280";
}

.bi-chevron-double-up::before {
  content: "\f281";
}

.bi-chevron-down::before {
  content: "\f282";
}

.bi-chevron-expand::before {
  content: "\f283";
}

.bi-chevron-left::before {
  content: "\f284";
}

.bi-chevron-right::before {
  content: "\f285";
}

.bi-chevron-up::before {
  content: "\f286";
}

.bi-circle-fill::before {
  content: "\f287";
}

.bi-circle-half::before {
  content: "\f288";
}

.bi-circle-square::before {
  content: "\f289";
}

.bi-circle::before {
  content: "\f28a";
}

.bi-clipboard-check::before {
  content: "\f28b";
}

.bi-clipboard-data::before {
  content: "\f28c";
}

.bi-clipboard-minus::before {
  content: "\f28d";
}

.bi-clipboard-plus::before {
  content: "\f28e";
}

.bi-clipboard-x::before {
  content: "\f28f";
}

.bi-clipboard::before {
  content: "\f290";
}

.bi-clock-fill::before {
  content: "\f291";
}

.bi-clock-history::before {
  content: "\f292";
}

.bi-clock::before {
  content: "\f293";
}

.bi-cloud-arrow-down-fill::before {
  content: "\f294";
}

.bi-cloud-arrow-down::before {
  content: "\f295";
}

.bi-cloud-arrow-up-fill::before {
  content: "\f296";
}

.bi-cloud-arrow-up::before {
  content: "\f297";
}

.bi-cloud-check-fill::before {
  content: "\f298";
}

.bi-cloud-check::before {
  content: "\f299";
}

.bi-cloud-download-fill::before {
  content: "\f29a";
}

.bi-cloud-download::before {
  content: "\f29b";
}

.bi-cloud-drizzle-fill::before {
  content: "\f29c";
}

.bi-cloud-drizzle::before {
  content: "\f29d";
}

.bi-cloud-fill::before {
  content: "\f29e";
}

.bi-cloud-fog-fill::before {
  content: "\f29f";
}

.bi-cloud-fog::before {
  content: "\f2a0";
}

.bi-cloud-fog2-fill::before {
  content: "\f2a1";
}

.bi-cloud-fog2::before {
  content: "\f2a2";
}

.bi-cloud-hail-fill::before {
  content: "\f2a3";
}

.bi-cloud-hail::before {
  content: "\f2a4";
}

.bi-cloud-haze-fill::before {
  content: "\f2a6";
}

.bi-cloud-haze::before {
  content: "\f2a7";
}

.bi-cloud-haze2-fill::before {
  content: "\f2a8";
}

.bi-cloud-lightning-fill::before {
  content: "\f2a9";
}

.bi-cloud-lightning-rain-fill::before {
  content: "\f2aa";
}

.bi-cloud-lightning-rain::before {
  content: "\f2ab";
}

.bi-cloud-lightning::before {
  content: "\f2ac";
}

.bi-cloud-minus-fill::before {
  content: "\f2ad";
}

.bi-cloud-minus::before {
  content: "\f2ae";
}

.bi-cloud-moon-fill::before {
  content: "\f2af";
}

.bi-cloud-moon::before {
  content: "\f2b0";
}

.bi-cloud-plus-fill::before {
  content: "\f2b1";
}

.bi-cloud-plus::before {
  content: "\f2b2";
}

.bi-cloud-rain-fill::before {
  content: "\f2b3";
}

.bi-cloud-rain-heavy-fill::before {
  content: "\f2b4";
}

.bi-cloud-rain-heavy::before {
  content: "\f2b5";
}

.bi-cloud-rain::before {
  content: "\f2b6";
}

.bi-cloud-slash-fill::before {
  content: "\f2b7";
}

.bi-cloud-slash::before {
  content: "\f2b8";
}

.bi-cloud-sleet-fill::before {
  content: "\f2b9";
}

.bi-cloud-sleet::before {
  content: "\f2ba";
}

.bi-cloud-snow-fill::before {
  content: "\f2bb";
}

.bi-cloud-snow::before {
  content: "\f2bc";
}

.bi-cloud-sun-fill::before {
  content: "\f2bd";
}

.bi-cloud-sun::before {
  content: "\f2be";
}

.bi-cloud-upload-fill::before {
  content: "\f2bf";
}

.bi-cloud-upload::before {
  content: "\f2c0";
}

.bi-cloud::before {
  content: "\f2c1";
}

.bi-clouds-fill::before {
  content: "\f2c2";
}

.bi-clouds::before {
  content: "\f2c3";
}

.bi-cloudy-fill::before {
  content: "\f2c4";
}

.bi-cloudy::before {
  content: "\f2c5";
}

.bi-code-slash::before {
  content: "\f2c6";
}

.bi-code-square::before {
  content: "\f2c7";
}

.bi-code::before {
  content: "\f2c8";
}

.bi-collection-fill::before {
  content: "\f2c9";
}

.bi-collection-play-fill::before {
  content: "\f2ca";
}

.bi-collection-play::before {
  content: "\f2cb";
}

.bi-collection::before {
  content: "\f2cc";
}

.bi-columns-gap::before {
  content: "\f2cd";
}

.bi-columns::before {
  content: "\f2ce";
}

.bi-command::before {
  content: "\f2cf";
}

.bi-compass-fill::before {
  content: "\f2d0";
}

.bi-compass::before {
  content: "\f2d1";
}

.bi-cone-striped::before {
  content: "\f2d2";
}

.bi-cone::before {
  content: "\f2d3";
}

.bi-controller::before {
  content: "\f2d4";
}

.bi-cpu-fill::before {
  content: "\f2d5";
}

.bi-cpu::before {
  content: "\f2d6";
}

.bi-credit-card-2-back-fill::before {
  content: "\f2d7";
}

.bi-credit-card-2-back::before {
  content: "\f2d8";
}

.bi-credit-card-2-front-fill::before {
  content: "\f2d9";
}

.bi-credit-card-2-front::before {
  content: "\f2da";
}

.bi-credit-card-fill::before {
  content: "\f2db";
}

.bi-credit-card::before {
  content: "\f2dc";
}

.bi-crop::before {
  content: "\f2dd";
}

.bi-cup-fill::before {
  content: "\f2de";
}

.bi-cup-straw::before {
  content: "\f2df";
}

.bi-cup::before {
  content: "\f2e0";
}

.bi-cursor-fill::before {
  content: "\f2e1";
}

.bi-cursor-text::before {
  content: "\f2e2";
}

.bi-cursor::before {
  content: "\f2e3";
}

.bi-dash-circle-dotted::before {
  content: "\f2e4";
}

.bi-dash-circle-fill::before {
  content: "\f2e5";
}

.bi-dash-circle::before {
  content: "\f2e6";
}

.bi-dash-square-dotted::before {
  content: "\f2e7";
}

.bi-dash-square-fill::before {
  content: "\f2e8";
}

.bi-dash-square::before {
  content: "\f2e9";
}

.bi-dash::before {
  content: "\f2ea";
}

.bi-diagram-2-fill::before {
  content: "\f2eb";
}

.bi-diagram-2::before {
  content: "\f2ec";
}

.bi-diagram-3-fill::before {
  content: "\f2ed";
}

.bi-diagram-3::before {
  content: "\f2ee";
}

.bi-diamond-fill::before {
  content: "\f2ef";
}

.bi-diamond-half::before {
  content: "\f2f0";
}

.bi-diamond::before {
  content: "\f2f1";
}

.bi-dice-1-fill::before {
  content: "\f2f2";
}

.bi-dice-1::before {
  content: "\f2f3";
}

.bi-dice-2-fill::before {
  content: "\f2f4";
}

.bi-dice-2::before {
  content: "\f2f5";
}

.bi-dice-3-fill::before {
  content: "\f2f6";
}

.bi-dice-3::before {
  content: "\f2f7";
}

.bi-dice-4-fill::before {
  content: "\f2f8";
}

.bi-dice-4::before {
  content: "\f2f9";
}

.bi-dice-5-fill::before {
  content: "\f2fa";
}

.bi-dice-5::before {
  content: "\f2fb";
}

.bi-dice-6-fill::before {
  content: "\f2fc";
}

.bi-dice-6::before {
  content: "\f2fd";
}

.bi-disc-fill::before {
  content: "\f2fe";
}

.bi-disc::before {
  content: "\f2ff";
}

.bi-discord::before {
  content: "\f300";
}

.bi-display-fill::before {
  content: "\f301";
}

.bi-display::before {
  content: "\f302";
}

.bi-distribute-horizontal::before {
  content: "\f303";
}

.bi-distribute-vertical::before {
  content: "\f304";
}

.bi-door-closed-fill::before {
  content: "\f305";
}

.bi-door-closed::before {
  content: "\f306";
}

.bi-door-open-fill::before {
  content: "\f307";
}

.bi-door-open::before {
  content: "\f308";
}

.bi-dot::before {
  content: "\f309";
}

.bi-download::before {
  content: "\f30a";
}

.bi-droplet-fill::before {
  content: "\f30b";
}

.bi-droplet-half::before {
  content: "\f30c";
}

.bi-droplet::before {
  content: "\f30d";
}

.bi-earbuds::before {
  content: "\f30e";
}

.bi-easel-fill::before {
  content: "\f30f";
}

.bi-easel::before {
  content: "\f310";
}

.bi-egg-fill::before {
  content: "\f311";
}

.bi-egg-fried::before {
  content: "\f312";
}

.bi-egg::before {
  content: "\f313";
}

.bi-eject-fill::before {
  content: "\f314";
}

.bi-eject::before {
  content: "\f315";
}

.bi-emoji-angry-fill::before {
  content: "\f316";
}

.bi-emoji-angry::before {
  content: "\f317";
}

.bi-emoji-dizzy-fill::before {
  content: "\f318";
}

.bi-emoji-dizzy::before {
  content: "\f319";
}

.bi-emoji-expressionless-fill::before {
  content: "\f31a";
}

.bi-emoji-expressionless::before {
  content: "\f31b";
}

.bi-emoji-frown-fill::before {
  content: "\f31c";
}

.bi-emoji-frown::before {
  content: "\f31d";
}

.bi-emoji-heart-eyes-fill::before {
  content: "\f31e";
}

.bi-emoji-heart-eyes::before {
  content: "\f31f";
}

.bi-emoji-laughing-fill::before {
  content: "\f320";
}

.bi-emoji-laughing::before {
  content: "\f321";
}

.bi-emoji-neutral-fill::before {
  content: "\f322";
}

.bi-emoji-neutral::before {
  content: "\f323";
}

.bi-emoji-smile-fill::before {
  content: "\f324";
}

.bi-emoji-smile-upside-down-fill::before {
  content: "\f325";
}

.bi-emoji-smile-upside-down::before {
  content: "\f326";
}

.bi-emoji-smile::before {
  content: "\f327";
}

.bi-emoji-sunglasses-fill::before {
  content: "\f328";
}

.bi-emoji-sunglasses::before {
  content: "\f329";
}

.bi-emoji-wink-fill::before {
  content: "\f32a";
}

.bi-emoji-wink::before {
  content: "\f32b";
}

.bi-envelope-fill::before {
  content: "\f32c";
}

.bi-envelope-open-fill::before {
  content: "\f32d";
}

.bi-envelope-open::before {
  content: "\f32e";
}

.bi-envelope::before {
  content: "\f32f";
}

.bi-eraser-fill::before {
  content: "\f330";
}

.bi-eraser::before {
  content: "\f331";
}

.bi-exclamation-circle-fill::before {
  content: "\f332";
}

.bi-exclamation-circle::before {
  content: "\f333";
}

.bi-exclamation-diamond-fill::before {
  content: "\f334";
}

.bi-exclamation-diamond::before {
  content: "\f335";
}

.bi-exclamation-octagon-fill::before {
  content: "\f336";
}

.bi-exclamation-octagon::before {
  content: "\f337";
}

.bi-exclamation-square-fill::before {
  content: "\f338";
}

.bi-exclamation-square::before {
  content: "\f339";
}

.bi-exclamation-triangle-fill::before {
  content: "\f33a";
}

.bi-exclamation-triangle::before {
  content: "\f33b";
}

.bi-exclamation::before {
  content: "\f33c";
}

.bi-exclude::before {
  content: "\f33d";
}

.bi-eye-fill::before {
  content: "\f33e";
}

.bi-eye-slash-fill::before {
  content: "\f33f";
}

.bi-eye-slash::before {
  content: "\f340";
}

.bi-eye::before {
  content: "\f341";
}

.bi-eyedropper::before {
  content: "\f342";
}

.bi-eyeglasses::before {
  content: "\f343";
}

.bi-facebook::before {
  content: "\f344";
}

.bi-file-arrow-down-fill::before {
  content: "\f345";
}

.bi-file-arrow-down::before {
  content: "\f346";
}

.bi-file-arrow-up-fill::before {
  content: "\f347";
}

.bi-file-arrow-up::before {
  content: "\f348";
}

.bi-file-bar-graph-fill::before {
  content: "\f349";
}

.bi-file-bar-graph::before {
  content: "\f34a";
}

.bi-file-binary-fill::before {
  content: "\f34b";
}

.bi-file-binary::before {
  content: "\f34c";
}

.bi-file-break-fill::before {
  content: "\f34d";
}

.bi-file-break::before {
  content: "\f34e";
}

.bi-file-check-fill::before {
  content: "\f34f";
}

.bi-file-check::before {
  content: "\f350";
}

.bi-file-code-fill::before {
  content: "\f351";
}

.bi-file-code::before {
  content: "\f352";
}

.bi-file-diff-fill::before {
  content: "\f353";
}

.bi-file-diff::before {
  content: "\f354";
}

.bi-file-earmark-arrow-down-fill::before {
  content: "\f355";
}

.bi-file-earmark-arrow-down::before {
  content: "\f356";
}

.bi-file-earmark-arrow-up-fill::before {
  content: "\f357";
}

.bi-file-earmark-arrow-up::before {
  content: "\f358";
}

.bi-file-earmark-bar-graph-fill::before {
  content: "\f359";
}

.bi-file-earmark-bar-graph::before {
  content: "\f35a";
}

.bi-file-earmark-binary-fill::before {
  content: "\f35b";
}

.bi-file-earmark-binary::before {
  content: "\f35c";
}

.bi-file-earmark-break-fill::before {
  content: "\f35d";
}

.bi-file-earmark-break::before {
  content: "\f35e";
}

.bi-file-earmark-check-fill::before {
  content: "\f35f";
}

.bi-file-earmark-check::before {
  content: "\f360";
}

.bi-file-earmark-code-fill::before {
  content: "\f361";
}

.bi-file-earmark-code::before {
  content: "\f362";
}

.bi-file-earmark-diff-fill::before {
  content: "\f363";
}

.bi-file-earmark-diff::before {
  content: "\f364";
}

.bi-file-earmark-easel-fill::before {
  content: "\f365";
}

.bi-file-earmark-easel::before {
  content: "\f366";
}

.bi-file-earmark-excel-fill::before {
  content: "\f367";
}

.bi-file-earmark-excel::before {
  content: "\f368";
}

.bi-file-earmark-fill::before {
  content: "\f369";
}

.bi-file-earmark-font-fill::before {
  content: "\f36a";
}

.bi-file-earmark-font::before {
  content: "\f36b";
}

.bi-file-earmark-image-fill::before {
  content: "\f36c";
}

.bi-file-earmark-image::before {
  content: "\f36d";
}

.bi-file-earmark-lock-fill::before {
  content: "\f36e";
}

.bi-file-earmark-lock::before {
  content: "\f36f";
}

.bi-file-earmark-lock2-fill::before {
  content: "\f370";
}

.bi-file-earmark-lock2::before {
  content: "\f371";
}

.bi-file-earmark-medical-fill::before {
  content: "\f372";
}

.bi-file-earmark-medical::before {
  content: "\f373";
}

.bi-file-earmark-minus-fill::before {
  content: "\f374";
}

.bi-file-earmark-minus::before {
  content: "\f375";
}

.bi-file-earmark-music-fill::before {
  content: "\f376";
}

.bi-file-earmark-music::before {
  content: "\f377";
}

.bi-file-earmark-person-fill::before {
  content: "\f378";
}

.bi-file-earmark-person::before {
  content: "\f379";
}

.bi-file-earmark-play-fill::before {
  content: "\f37a";
}

.bi-file-earmark-play::before {
  content: "\f37b";
}

.bi-file-earmark-plus-fill::before {
  content: "\f37c";
}

.bi-file-earmark-plus::before {
  content: "\f37d";
}

.bi-file-earmark-post-fill::before {
  content: "\f37e";
}

.bi-file-earmark-post::before {
  content: "\f37f";
}

.bi-file-earmark-ppt-fill::before {
  content: "\f380";
}

.bi-file-earmark-ppt::before {
  content: "\f381";
}

.bi-file-earmark-richtext-fill::before {
  content: "\f382";
}

.bi-file-earmark-richtext::before {
  content: "\f383";
}

.bi-file-earmark-ruled-fill::before {
  content: "\f384";
}

.bi-file-earmark-ruled::before {
  content: "\f385";
}

.bi-file-earmark-slides-fill::before {
  content: "\f386";
}

.bi-file-earmark-slides::before {
  content: "\f387";
}

.bi-file-earmark-spreadsheet-fill::before {
  content: "\f388";
}

.bi-file-earmark-spreadsheet::before {
  content: "\f389";
}

.bi-file-earmark-text-fill::before {
  content: "\f38a";
}

.bi-file-earmark-text::before {
  content: "\f38b";
}

.bi-file-earmark-word-fill::before {
  content: "\f38c";
}

.bi-file-earmark-word::before {
  content: "\f38d";
}

.bi-file-earmark-x-fill::before {
  content: "\f38e";
}

.bi-file-earmark-x::before {
  content: "\f38f";
}

.bi-file-earmark-zip-fill::before {
  content: "\f390";
}

.bi-file-earmark-zip::before {
  content: "\f391";
}

.bi-file-earmark::before {
  content: "\f392";
}

.bi-file-easel-fill::before {
  content: "\f393";
}

.bi-file-easel::before {
  content: "\f394";
}

.bi-file-excel-fill::before {
  content: "\f395";
}

.bi-file-excel::before {
  content: "\f396";
}

.bi-file-fill::before {
  content: "\f397";
}

.bi-file-font-fill::before {
  content: "\f398";
}

.bi-file-font::before {
  content: "\f399";
}

.bi-file-image-fill::before {
  content: "\f39a";
}

.bi-file-image::before {
  content: "\f39b";
}

.bi-file-lock-fill::before {
  content: "\f39c";
}

.bi-file-lock::before {
  content: "\f39d";
}

.bi-file-lock2-fill::before {
  content: "\f39e";
}

.bi-file-lock2::before {
  content: "\f39f";
}

.bi-file-medical-fill::before {
  content: "\f3a0";
}

.bi-file-medical::before {
  content: "\f3a1";
}

.bi-file-minus-fill::before {
  content: "\f3a2";
}

.bi-file-minus::before {
  content: "\f3a3";
}

.bi-file-music-fill::before {
  content: "\f3a4";
}

.bi-file-music::before {
  content: "\f3a5";
}

.bi-file-person-fill::before {
  content: "\f3a6";
}

.bi-file-person::before {
  content: "\f3a7";
}

.bi-file-play-fill::before {
  content: "\f3a8";
}

.bi-file-play::before {
  content: "\f3a9";
}

.bi-file-plus-fill::before {
  content: "\f3aa";
}

.bi-file-plus::before {
  content: "\f3ab";
}

.bi-file-post-fill::before {
  content: "\f3ac";
}

.bi-file-post::before {
  content: "\f3ad";
}

.bi-file-ppt-fill::before {
  content: "\f3ae";
}

.bi-file-ppt::before {
  content: "\f3af";
}

.bi-file-richtext-fill::before {
  content: "\f3b0";
}

.bi-file-richtext::before {
  content: "\f3b1";
}

.bi-file-ruled-fill::before {
  content: "\f3b2";
}

.bi-file-ruled::before {
  content: "\f3b3";
}

.bi-file-slides-fill::before {
  content: "\f3b4";
}

.bi-file-slides::before {
  content: "\f3b5";
}

.bi-file-spreadsheet-fill::before {
  content: "\f3b6";
}

.bi-file-spreadsheet::before {
  content: "\f3b7";
}

.bi-file-text-fill::before {
  content: "\f3b8";
}

.bi-file-text::before {
  content: "\f3b9";
}

.bi-file-word-fill::before {
  content: "\f3ba";
}

.bi-file-word::before {
  content: "\f3bb";
}

.bi-file-x-fill::before {
  content: "\f3bc";
}

.bi-file-x::before {
  content: "\f3bd";
}

.bi-file-zip-fill::before {
  content: "\f3be";
}

.bi-file-zip::before {
  content: "\f3bf";
}

.bi-file::before {
  content: "\f3c0";
}

.bi-files-alt::before {
  content: "\f3c1";
}

.bi-files::before {
  content: "\f3c2";
}

.bi-film::before {
  content: "\f3c3";
}

.bi-filter-circle-fill::before {
  content: "\f3c4";
}

.bi-filter-circle::before {
  content: "\f3c5";
}

.bi-filter-left::before {
  content: "\f3c6";
}

.bi-filter-right::before {
  content: "\f3c7";
}

.bi-filter-square-fill::before {
  content: "\f3c8";
}

.bi-filter-square::before {
  content: "\f3c9";
}

.bi-filter::before {
  content: "\f3ca";
}

.bi-flag-fill::before {
  content: "\f3cb";
}

.bi-flag::before {
  content: "\f3cc";
}

.bi-flower1::before {
  content: "\f3cd";
}

.bi-flower2::before {
  content: "\f3ce";
}

.bi-flower3::before {
  content: "\f3cf";
}

.bi-folder-check::before {
  content: "\f3d0";
}

.bi-folder-fill::before {
  content: "\f3d1";
}

.bi-folder-minus::before {
  content: "\f3d2";
}

.bi-folder-plus::before {
  content: "\f3d3";
}

.bi-folder-symlink-fill::before {
  content: "\f3d4";
}

.bi-folder-symlink::before {
  content: "\f3d5";
}

.bi-folder-x::before {
  content: "\f3d6";
}

.bi-folder::before {
  content: "\f3d7";
}

.bi-folder2-open::before {
  content: "\f3d8";
}

.bi-folder2::before {
  content: "\f3d9";
}

.bi-fonts::before {
  content: "\f3da";
}

.bi-forward-fill::before {
  content: "\f3db";
}

.bi-forward::before {
  content: "\f3dc";
}

.bi-front::before {
  content: "\f3dd";
}

.bi-fullscreen-exit::before {
  content: "\f3de";
}

.bi-fullscreen::before {
  content: "\f3df";
}

.bi-funnel-fill::before {
  content: "\f3e0";
}

.bi-funnel::before {
  content: "\f3e1";
}

.bi-gear-fill::before {
  content: "\f3e2";
}

.bi-gear-wide-connected::before {
  content: "\f3e3";
}

.bi-gear-wide::before {
  content: "\f3e4";
}

.bi-gear::before {
  content: "\f3e5";
}

.bi-gem::before {
  content: "\f3e6";
}

.bi-geo-alt-fill::before {
  content: "\f3e7";
}

.bi-geo-alt::before {
  content: "\f3e8";
}

.bi-geo-fill::before {
  content: "\f3e9";
}

.bi-geo::before {
  content: "\f3ea";
}

.bi-gift-fill::before {
  content: "\f3eb";
}

.bi-gift::before {
  content: "\f3ec";
}

.bi-github::before {
  content: "\f3ed";
}

.bi-globe::before {
  content: "\f3ee";
}

.bi-globe2::before {
  content: "\f3ef";
}

.bi-google::before {
  content: "\f3f0";
}

.bi-graph-down::before {
  content: "\f3f1";
}

.bi-graph-up::before {
  content: "\f3f2";
}

.bi-grid-1x2-fill::before {
  content: "\f3f3";
}

.bi-grid-1x2::before {
  content: "\f3f4";
}

.bi-grid-3x2-gap-fill::before {
  content: "\f3f5";
}

.bi-grid-3x2-gap::before {
  content: "\f3f6";
}

.bi-grid-3x2::before {
  content: "\f3f7";
}

.bi-grid-3x3-gap-fill::before {
  content: "\f3f8";
}

.bi-grid-3x3-gap::before {
  content: "\f3f9";
}

.bi-grid-3x3::before {
  content: "\f3fa";
}

.bi-grid-fill::before {
  content: "\f3fb";
}

.bi-grid::before {
  content: "\f3fc";
}

.bi-grip-horizontal::before {
  content: "\f3fd";
}

.bi-grip-vertical::before {
  content: "\f3fe";
}

.bi-hammer::before {
  content: "\f3ff";
}

.bi-hand-index-fill::before {
  content: "\f400";
}

.bi-hand-index-thumb-fill::before {
  content: "\f401";
}

.bi-hand-index-thumb::before {
  content: "\f402";
}

.bi-hand-index::before {
  content: "\f403";
}

.bi-hand-thumbs-down-fill::before {
  content: "\f404";
}

.bi-hand-thumbs-down::before {
  content: "\f405";
}

.bi-hand-thumbs-up-fill::before {
  content: "\f406";
}

.bi-hand-thumbs-up::before {
  content: "\f407";
}

.bi-handbag-fill::before {
  content: "\f408";
}

.bi-handbag::before {
  content: "\f409";
}

.bi-hash::before {
  content: "\f40a";
}

.bi-hdd-fill::before {
  content: "\f40b";
}

.bi-hdd-network-fill::before {
  content: "\f40c";
}

.bi-hdd-network::before {
  content: "\f40d";
}

.bi-hdd-rack-fill::before {
  content: "\f40e";
}

.bi-hdd-rack::before {
  content: "\f40f";
}

.bi-hdd-stack-fill::before {
  content: "\f410";
}

.bi-hdd-stack::before {
  content: "\f411";
}

.bi-hdd::before {
  content: "\f412";
}

.bi-headphones::before {
  content: "\f413";
}

.bi-headset::before {
  content: "\f414";
}

.bi-heart-fill::before {
  content: "\f415";
}

.bi-heart-half::before {
  content: "\f416";
}

.bi-heart::before {
  content: "\f417";
}

.bi-heptagon-fill::before {
  content: "\f418";
}

.bi-heptagon-half::before {
  content: "\f419";
}

.bi-heptagon::before {
  content: "\f41a";
}

.bi-hexagon-fill::before {
  content: "\f41b";
}

.bi-hexagon-half::before {
  content: "\f41c";
}

.bi-hexagon::before {
  content: "\f41d";
}

.bi-hourglass-bottom::before {
  content: "\f41e";
}

.bi-hourglass-split::before {
  content: "\f41f";
}

.bi-hourglass-top::before {
  content: "\f420";
}

.bi-hourglass::before {
  content: "\f421";
}

.bi-house-door-fill::before {
  content: "\f422";
}

.bi-house-door::before {
  content: "\f423";
}

.bi-house-fill::before {
  content: "\f424";
}

.bi-house::before {
  content: "\f425";
}

.bi-hr::before {
  content: "\f426";
}

.bi-hurricane::before {
  content: "\f427";
}

.bi-image-alt::before {
  content: "\f428";
}

.bi-image-fill::before {
  content: "\f429";
}

.bi-image::before {
  content: "\f42a";
}

.bi-images::before {
  content: "\f42b";
}

.bi-inbox-fill::before {
  content: "\f42c";
}

.bi-inbox::before {
  content: "\f42d";
}

.bi-inboxes-fill::before {
  content: "\f42e";
}

.bi-inboxes::before {
  content: "\f42f";
}

.bi-info-circle-fill::before {
  content: "\f430";
}

.bi-info-circle::before {
  content: "\f431";
}

.bi-info-square-fill::before {
  content: "\f432";
}

.bi-info-square::before {
  content: "\f433";
}

.bi-info::before {
  content: "\f434";
}

.bi-input-cursor-text::before {
  content: "\f435";
}

.bi-input-cursor::before {
  content: "\f436";
}

.bi-instagram::before {
  content: "\f437";
}

.bi-intersect::before {
  content: "\f438";
}

.bi-journal-album::before {
  content: "\f439";
}

.bi-journal-arrow-down::before {
  content: "\f43a";
}

.bi-journal-arrow-up::before {
  content: "\f43b";
}

.bi-journal-bookmark-fill::before {
  content: "\f43c";
}

.bi-journal-bookmark::before {
  content: "\f43d";
}

.bi-journal-check::before {
  content: "\f43e";
}

.bi-journal-code::before {
  content: "\f43f";
}

.bi-journal-medical::before {
  content: "\f440";
}

.bi-journal-minus::before {
  content: "\f441";
}

.bi-journal-plus::before {
  content: "\f442";
}

.bi-journal-richtext::before {
  content: "\f443";
}

.bi-journal-text::before {
  content: "\f444";
}

.bi-journal-x::before {
  content: "\f445";
}

.bi-journal::before {
  content: "\f446";
}

.bi-journals::before {
  content: "\f447";
}

.bi-joystick::before {
  content: "\f448";
}

.bi-justify-left::before {
  content: "\f449";
}

.bi-justify-right::before {
  content: "\f44a";
}

.bi-justify::before {
  content: "\f44b";
}

.bi-kanban-fill::before {
  content: "\f44c";
}

.bi-kanban::before {
  content: "\f44d";
}

.bi-key-fill::before {
  content: "\f44e";
}

.bi-key::before {
  content: "\f44f";
}

.bi-keyboard-fill::before {
  content: "\f450";
}

.bi-keyboard::before {
  content: "\f451";
}

.bi-ladder::before {
  content: "\f452";
}

.bi-lamp-fill::before {
  content: "\f453";
}

.bi-lamp::before {
  content: "\f454";
}

.bi-laptop-fill::before {
  content: "\f455";
}

.bi-laptop::before {
  content: "\f456";
}

.bi-layer-backward::before {
  content: "\f457";
}

.bi-layer-forward::before {
  content: "\f458";
}

.bi-layers-fill::before {
  content: "\f459";
}

.bi-layers-half::before {
  content: "\f45a";
}

.bi-layers::before {
  content: "\f45b";
}

.bi-layout-sidebar-inset-reverse::before {
  content: "\f45c";
}

.bi-layout-sidebar-inset::before {
  content: "\f45d";
}

.bi-layout-sidebar-reverse::before {
  content: "\f45e";
}

.bi-layout-sidebar::before {
  content: "\f45f";
}

.bi-layout-split::before {
  content: "\f460";
}

.bi-layout-text-sidebar-reverse::before {
  content: "\f461";
}

.bi-layout-text-sidebar::before {
  content: "\f462";
}

.bi-layout-text-window-reverse::before {
  content: "\f463";
}

.bi-layout-text-window::before {
  content: "\f464";
}

.bi-layout-three-columns::before {
  content: "\f465";
}

.bi-layout-wtf::before {
  content: "\f466";
}

.bi-life-preserver::before {
  content: "\f467";
}

.bi-lightbulb-fill::before {
  content: "\f468";
}

.bi-lightbulb-off-fill::before {
  content: "\f469";
}

.bi-lightbulb-off::before {
  content: "\f46a";
}

.bi-lightbulb::before {
  content: "\f46b";
}

.bi-lightning-charge-fill::before {
  content: "\f46c";
}

.bi-lightning-charge::before {
  content: "\f46d";
}

.bi-lightning-fill::before {
  content: "\f46e";
}

.bi-lightning::before {
  content: "\f46f";
}

.bi-link-45deg::before {
  content: "\f470";
}

.bi-link::before {
  content: "\f471";
}

.bi-linkedin::before {
  content: "\f472";
}

.bi-list-check::before {
  content: "\f473";
}

.bi-list-nested::before {
  content: "\f474";
}

.bi-list-ol::before {
  content: "\f475";
}

.bi-list-stars::before {
  content: "\f476";
}

.bi-list-task::before {
  content: "\f477";
}

.bi-list-ul::before {
  content: "\f478";
}

.bi-list::before {
  content: "\f479";
}

.bi-lock-fill::before {
  content: "\f47a";
}

.bi-lock::before {
  content: "\f47b";
}

.bi-mailbox::before {
  content: "\f47c";
}

.bi-mailbox2::before {
  content: "\f47d";
}

.bi-map-fill::before {
  content: "\f47e";
}

.bi-map::before {
  content: "\f47f";
}

.bi-markdown-fill::before {
  content: "\f480";
}

.bi-markdown::before {
  content: "\f481";
}

.bi-mask::before {
  content: "\f482";
}

.bi-megaphone-fill::before {
  content: "\f483";
}

.bi-megaphone::before {
  content: "\f484";
}

.bi-menu-app-fill::before {
  content: "\f485";
}

.bi-menu-app::before {
  content: "\f486";
}

.bi-menu-button-fill::before {
  content: "\f487";
}

.bi-menu-button-wide-fill::before {
  content: "\f488";
}

.bi-menu-button-wide::before {
  content: "\f489";
}

.bi-menu-button::before {
  content: "\f48a";
}

.bi-menu-down::before {
  content: "\f48b";
}

.bi-menu-up::before {
  content: "\f48c";
}

.bi-mic-fill::before {
  content: "\f48d";
}

.bi-mic-mute-fill::before {
  content: "\f48e";
}

.bi-mic-mute::before {
  content: "\f48f";
}

.bi-mic::before {
  content: "\f490";
}

.bi-minecart-loaded::before {
  content: "\f491";
}

.bi-minecart::before {
  content: "\f492";
}

.bi-moisture::before {
  content: "\f493";
}

.bi-moon-fill::before {
  content: "\f494";
}

.bi-moon-stars-fill::before {
  content: "\f495";
}

.bi-moon-stars::before {
  content: "\f496";
}

.bi-moon::before {
  content: "\f497";
}

.bi-mouse-fill::before {
  content: "\f498";
}

.bi-mouse::before {
  content: "\f499";
}

.bi-mouse2-fill::before {
  content: "\f49a";
}

.bi-mouse2::before {
  content: "\f49b";
}

.bi-mouse3-fill::before {
  content: "\f49c";
}

.bi-mouse3::before {
  content: "\f49d";
}

.bi-music-note-beamed::before {
  content: "\f49e";
}

.bi-music-note-list::before {
  content: "\f49f";
}

.bi-music-note::before {
  content: "\f4a0";
}

.bi-music-player-fill::before {
  content: "\f4a1";
}

.bi-music-player::before {
  content: "\f4a2";
}

.bi-newspaper::before {
  content: "\f4a3";
}

.bi-node-minus-fill::before {
  content: "\f4a4";
}

.bi-node-minus::before {
  content: "\f4a5";
}

.bi-node-plus-fill::before {
  content: "\f4a6";
}

.bi-node-plus::before {
  content: "\f4a7";
}

.bi-nut-fill::before {
  content: "\f4a8";
}

.bi-nut::before {
  content: "\f4a9";
}

.bi-octagon-fill::before {
  content: "\f4aa";
}

.bi-octagon-half::before {
  content: "\f4ab";
}

.bi-octagon::before {
  content: "\f4ac";
}

.bi-option::before {
  content: "\f4ad";
}

.bi-outlet::before {
  content: "\f4ae";
}

.bi-paint-bucket::before {
  content: "\f4af";
}

.bi-palette-fill::before {
  content: "\f4b0";
}

.bi-palette::before {
  content: "\f4b1";
}

.bi-palette2::before {
  content: "\f4b2";
}

.bi-paperclip::before {
  content: "\f4b3";
}

.bi-paragraph::before {
  content: "\f4b4";
}

.bi-patch-check-fill::before {
  content: "\f4b5";
}

.bi-patch-check::before {
  content: "\f4b6";
}

.bi-patch-exclamation-fill::before {
  content: "\f4b7";
}

.bi-patch-exclamation::before {
  content: "\f4b8";
}

.bi-patch-minus-fill::before {
  content: "\f4b9";
}

.bi-patch-minus::before {
  content: "\f4ba";
}

.bi-patch-plus-fill::before {
  content: "\f4bb";
}

.bi-patch-plus::before {
  content: "\f4bc";
}

.bi-patch-question-fill::before {
  content: "\f4bd";
}

.bi-patch-question::before {
  content: "\f4be";
}

.bi-pause-btn-fill::before {
  content: "\f4bf";
}

.bi-pause-btn::before {
  content: "\f4c0";
}

.bi-pause-circle-fill::before {
  content: "\f4c1";
}

.bi-pause-circle::before {
  content: "\f4c2";
}

.bi-pause-fill::before {
  content: "\f4c3";
}

.bi-pause::before {
  content: "\f4c4";
}

.bi-peace-fill::before {
  content: "\f4c5";
}

.bi-peace::before {
  content: "\f4c6";
}

.bi-pen-fill::before {
  content: "\f4c7";
}

.bi-pen::before {
  content: "\f4c8";
}

.bi-pencil-fill::before {
  content: "\f4c9";
}

.bi-pencil-square::before {
  content: "\f4ca";
}

.bi-pencil::before {
  content: "\f4cb";
}

.bi-pentagon-fill::before {
  content: "\f4cc";
}

.bi-pentagon-half::before {
  content: "\f4cd";
}

.bi-pentagon::before {
  content: "\f4ce";
}

.bi-people-fill::before {
  content: "\f4cf";
}

.bi-people::before {
  content: "\f4d0";
}

.bi-percent::before {
  content: "\f4d1";
}

.bi-person-badge-fill::before {
  content: "\f4d2";
}

.bi-person-badge::before {
  content: "\f4d3";
}

.bi-person-bounding-box::before {
  content: "\f4d4";
}

.bi-person-check-fill::before {
  content: "\f4d5";
}

.bi-person-check::before {
  content: "\f4d6";
}

.bi-person-circle::before {
  content: "\f4d7";
}

.bi-person-dash-fill::before {
  content: "\f4d8";
}

.bi-person-dash::before {
  content: "\f4d9";
}

.bi-person-fill::before {
  content: "\f4da";
}

.bi-person-lines-fill::before {
  content: "\f4db";
}

.bi-person-plus-fill::before {
  content: "\f4dc";
}

.bi-person-plus::before {
  content: "\f4dd";
}

.bi-person-square::before {
  content: "\f4de";
}

.bi-person-x-fill::before {
  content: "\f4df";
}

.bi-person-x::before {
  content: "\f4e0";
}

.bi-person::before {
  content: "\f4e1";
}

.bi-phone-fill::before {
  content: "\f4e2";
}

.bi-phone-landscape-fill::before {
  content: "\f4e3";
}

.bi-phone-landscape::before {
  content: "\f4e4";
}

.bi-phone-vibrate-fill::before {
  content: "\f4e5";
}

.bi-phone-vibrate::before {
  content: "\f4e6";
}

.bi-phone::before {
  content: "\f4e7";
}

.bi-pie-chart-fill::before {
  content: "\f4e8";
}

.bi-pie-chart::before {
  content: "\f4e9";
}

.bi-pin-angle-fill::before {
  content: "\f4ea";
}

.bi-pin-angle::before {
  content: "\f4eb";
}

.bi-pin-fill::before {
  content: "\f4ec";
}

.bi-pin::before {
  content: "\f4ed";
}

.bi-pip-fill::before {
  content: "\f4ee";
}

.bi-pip::before {
  content: "\f4ef";
}

.bi-play-btn-fill::before {
  content: "\f4f0";
}

.bi-play-btn::before {
  content: "\f4f1";
}

.bi-play-circle-fill::before {
  content: "\f4f2";
}

.bi-play-circle::before {
  content: "\f4f3";
}

.bi-play-fill::before {
  content: "\f4f4";
}

.bi-play::before {
  content: "\f4f5";
}

.bi-plug-fill::before {
  content: "\f4f6";
}

.bi-plug::before {
  content: "\f4f7";
}

.bi-plus-circle-dotted::before {
  content: "\f4f8";
}

.bi-plus-circle-fill::before {
  content: "\f4f9";
}

.bi-plus-circle::before {
  content: "\f4fa";
}

.bi-plus-square-dotted::before {
  content: "\f4fb";
}

.bi-plus-square-fill::before {
  content: "\f4fc";
}

.bi-plus-square::before {
  content: "\f4fd";
}

.bi-plus::before {
  content: "\f4fe";
}

.bi-power::before {
  content: "\f4ff";
}

.bi-printer-fill::before {
  content: "\f500";
}

.bi-printer::before {
  content: "\f501";
}

.bi-puzzle-fill::before {
  content: "\f502";
}

.bi-puzzle::before {
  content: "\f503";
}

.bi-question-circle-fill::before {
  content: "\f504";
}

.bi-question-circle::before {
  content: "\f505";
}

.bi-question-diamond-fill::before {
  content: "\f506";
}

.bi-question-diamond::before {
  content: "\f507";
}

.bi-question-octagon-fill::before {
  content: "\f508";
}

.bi-question-octagon::before {
  content: "\f509";
}

.bi-question-square-fill::before {
  content: "\f50a";
}

.bi-question-square::before {
  content: "\f50b";
}

.bi-question::before {
  content: "\f50c";
}

.bi-rainbow::before {
  content: "\f50d";
}

.bi-receipt-cutoff::before {
  content: "\f50e";
}

.bi-receipt::before {
  content: "\f50f";
}

.bi-reception-0::before {
  content: "\f510";
}

.bi-reception-1::before {
  content: "\f511";
}

.bi-reception-2::before {
  content: "\f512";
}

.bi-reception-3::before {
  content: "\f513";
}

.bi-reception-4::before {
  content: "\f514";
}

.bi-record-btn-fill::before {
  content: "\f515";
}

.bi-record-btn::before {
  content: "\f516";
}

.bi-record-circle-fill::before {
  content: "\f517";
}

.bi-record-circle::before {
  content: "\f518";
}

.bi-record-fill::before {
  content: "\f519";
}

.bi-record::before {
  content: "\f51a";
}

.bi-record2-fill::before {
  content: "\f51b";
}

.bi-record2::before {
  content: "\f51c";
}

.bi-reply-all-fill::before {
  content: "\f51d";
}

.bi-reply-all::before {
  content: "\f51e";
}

.bi-reply-fill::before {
  content: "\f51f";
}

.bi-reply::before {
  content: "\f520";
}

.bi-rss-fill::before {
  content: "\f521";
}

.bi-rss::before {
  content: "\f522";
}

.bi-rulers::before {
  content: "\f523";
}

.bi-save-fill::before {
  content: "\f524";
}

.bi-save::before {
  content: "\f525";
}

.bi-save2-fill::before {
  content: "\f526";
}

.bi-save2::before {
  content: "\f527";
}

.bi-scissors::before {
  content: "\f528";
}

.bi-screwdriver::before {
  content: "\f529";
}

.bi-search::before {
  content: "\f52a";
}

.bi-segmented-nav::before {
  content: "\f52b";
}

.bi-server::before {
  content: "\f52c";
}

.bi-share-fill::before {
  content: "\f52d";
}

.bi-share::before {
  content: "\f52e";
}

.bi-shield-check::before {
  content: "\f52f";
}

.bi-shield-exclamation::before {
  content: "\f530";
}

.bi-shield-fill-check::before {
  content: "\f531";
}

.bi-shield-fill-exclamation::before {
  content: "\f532";
}

.bi-shield-fill-minus::before {
  content: "\f533";
}

.bi-shield-fill-plus::before {
  content: "\f534";
}

.bi-shield-fill-x::before {
  content: "\f535";
}

.bi-shield-fill::before {
  content: "\f536";
}

.bi-shield-lock-fill::before {
  content: "\f537";
}

.bi-shield-lock::before {
  content: "\f538";
}

.bi-shield-minus::before {
  content: "\f539";
}

.bi-shield-plus::before {
  content: "\f53a";
}

.bi-shield-shaded::before {
  content: "\f53b";
}

.bi-shield-slash-fill::before {
  content: "\f53c";
}

.bi-shield-slash::before {
  content: "\f53d";
}

.bi-shield-x::before {
  content: "\f53e";
}

.bi-shield::before {
  content: "\f53f";
}

.bi-shift-fill::before {
  content: "\f540";
}

.bi-shift::before {
  content: "\f541";
}

.bi-shop-window::before {
  content: "\f542";
}

.bi-shop::before {
  content: "\f543";
}

.bi-shuffle::before {
  content: "\f544";
}

.bi-signpost-2-fill::before {
  content: "\f545";
}

.bi-signpost-2::before {
  content: "\f546";
}

.bi-signpost-fill::before {
  content: "\f547";
}

.bi-signpost-split-fill::before {
  content: "\f548";
}

.bi-signpost-split::before {
  content: "\f549";
}

.bi-signpost::before {
  content: "\f54a";
}

.bi-sim-fill::before {
  content: "\f54b";
}

.bi-sim::before {
  content: "\f54c";
}

.bi-skip-backward-btn-fill::before {
  content: "\f54d";
}

.bi-skip-backward-btn::before {
  content: "\f54e";
}

.bi-skip-backward-circle-fill::before {
  content: "\f54f";
}

.bi-skip-backward-circle::before {
  content: "\f550";
}

.bi-skip-backward-fill::before {
  content: "\f551";
}

.bi-skip-backward::before {
  content: "\f552";
}

.bi-skip-end-btn-fill::before {
  content: "\f553";
}

.bi-skip-end-btn::before {
  content: "\f554";
}

.bi-skip-end-circle-fill::before {
  content: "\f555";
}

.bi-skip-end-circle::before {
  content: "\f556";
}

.bi-skip-end-fill::before {
  content: "\f557";
}

.bi-skip-end::before {
  content: "\f558";
}

.bi-skip-forward-btn-fill::before {
  content: "\f559";
}

.bi-skip-forward-btn::before {
  content: "\f55a";
}

.bi-skip-forward-circle-fill::before {
  content: "\f55b";
}

.bi-skip-forward-circle::before {
  content: "\f55c";
}

.bi-skip-forward-fill::before {
  content: "\f55d";
}

.bi-skip-forward::before {
  content: "\f55e";
}

.bi-skip-start-btn-fill::before {
  content: "\f55f";
}

.bi-skip-start-btn::before {
  content: "\f560";
}

.bi-skip-start-circle-fill::before {
  content: "\f561";
}

.bi-skip-start-circle::before {
  content: "\f562";
}

.bi-skip-start-fill::before {
  content: "\f563";
}

.bi-skip-start::before {
  content: "\f564";
}

.bi-slack::before {
  content: "\f565";
}

.bi-slash-circle-fill::before {
  content: "\f566";
}

.bi-slash-circle::before {
  content: "\f567";
}

.bi-slash-square-fill::before {
  content: "\f568";
}

.bi-slash-square::before {
  content: "\f569";
}

.bi-slash::before {
  content: "\f56a";
}

.bi-sliders::before {
  content: "\f56b";
}

.bi-smartwatch::before {
  content: "\f56c";
}

.bi-snow::before {
  content: "\f56d";
}

.bi-snow2::before {
  content: "\f56e";
}

.bi-snow3::before {
  content: "\f56f";
}

.bi-sort-alpha-down-alt::before {
  content: "\f570";
}

.bi-sort-alpha-down::before {
  content: "\f571";
}

.bi-sort-alpha-up-alt::before {
  content: "\f572";
}

.bi-sort-alpha-up::before {
  content: "\f573";
}

.bi-sort-down-alt::before {
  content: "\f574";
}

.bi-sort-down::before {
  content: "\f575";
}

.bi-sort-numeric-down-alt::before {
  content: "\f576";
}

.bi-sort-numeric-down::before {
  content: "\f577";
}

.bi-sort-numeric-up-alt::before {
  content: "\f578";
}

.bi-sort-numeric-up::before {
  content: "\f579";
}

.bi-sort-up-alt::before {
  content: "\f57a";
}

.bi-sort-up::before {
  content: "\f57b";
}

.bi-soundwave::before {
  content: "\f57c";
}

.bi-speaker-fill::before {
  content: "\f57d";
}

.bi-speaker::before {
  content: "\f57e";
}

.bi-speedometer::before {
  content: "\f57f";
}

.bi-speedometer2::before {
  content: "\f580";
}

.bi-spellcheck::before {
  content: "\f581";
}

.bi-square-fill::before {
  content: "\f582";
}

.bi-square-half::before {
  content: "\f583";
}

.bi-square::before {
  content: "\f584";
}

.bi-stack::before {
  content: "\f585";
}

.bi-star-fill::before {
  content: "\f586";
}

.bi-star-half::before {
  content: "\f587";
}

.bi-star::before {
  content: "\f588";
}

.bi-stars::before {
  content: "\f589";
}

.bi-stickies-fill::before {
  content: "\f58a";
}

.bi-stickies::before {
  content: "\f58b";
}

.bi-sticky-fill::before {
  content: "\f58c";
}

.bi-sticky::before {
  content: "\f58d";
}

.bi-stop-btn-fill::before {
  content: "\f58e";
}

.bi-stop-btn::before {
  content: "\f58f";
}

.bi-stop-circle-fill::before {
  content: "\f590";
}

.bi-stop-circle::before {
  content: "\f591";
}

.bi-stop-fill::before {
  content: "\f592";
}

.bi-stop::before {
  content: "\f593";
}

.bi-stoplights-fill::before {
  content: "\f594";
}

.bi-stoplights::before {
  content: "\f595";
}

.bi-stopwatch-fill::before {
  content: "\f596";
}

.bi-stopwatch::before {
  content: "\f597";
}

.bi-subtract::before {
  content: "\f598";
}

.bi-suit-club-fill::before {
  content: "\f599";
}

.bi-suit-club::before {
  content: "\f59a";
}

.bi-suit-diamond-fill::before {
  content: "\f59b";
}

.bi-suit-diamond::before {
  content: "\f59c";
}

.bi-suit-heart-fill::before {
  content: "\f59d";
}

.bi-suit-heart::before {
  content: "\f59e";
}

.bi-suit-spade-fill::before {
  content: "\f59f";
}

.bi-suit-spade::before {
  content: "\f5a0";
}

.bi-sun-fill::before {
  content: "\f5a1";
}

.bi-sun::before {
  content: "\f5a2";
}

.bi-sunglasses::before {
  content: "\f5a3";
}

.bi-sunrise-fill::before {
  content: "\f5a4";
}

.bi-sunrise::before {
  content: "\f5a5";
}

.bi-sunset-fill::before {
  content: "\f5a6";
}

.bi-sunset::before {
  content: "\f5a7";
}

.bi-symmetry-horizontal::before {
  content: "\f5a8";
}

.bi-symmetry-vertical::before {
  content: "\f5a9";
}

.bi-table::before {
  content: "\f5aa";
}

.bi-tablet-fill::before {
  content: "\f5ab";
}

.bi-tablet-landscape-fill::before {
  content: "\f5ac";
}

.bi-tablet-landscape::before {
  content: "\f5ad";
}

.bi-tablet::before {
  content: "\f5ae";
}

.bi-tag-fill::before {
  content: "\f5af";
}

.bi-tag::before {
  content: "\f5b0";
}

.bi-tags-fill::before {
  content: "\f5b1";
}

.bi-tags::before {
  content: "\f5b2";
}

.bi-telegram::before {
  content: "\f5b3";
}

.bi-telephone-fill::before {
  content: "\f5b4";
}

.bi-telephone-forward-fill::before {
  content: "\f5b5";
}

.bi-telephone-forward::before {
  content: "\f5b6";
}

.bi-telephone-inbound-fill::before {
  content: "\f5b7";
}

.bi-telephone-inbound::before {
  content: "\f5b8";
}

.bi-telephone-minus-fill::before {
  content: "\f5b9";
}

.bi-telephone-minus::before {
  content: "\f5ba";
}

.bi-telephone-outbound-fill::before {
  content: "\f5bb";
}

.bi-telephone-outbound::before {
  content: "\f5bc";
}

.bi-telephone-plus-fill::before {
  content: "\f5bd";
}

.bi-telephone-plus::before {
  content: "\f5be";
}

.bi-telephone-x-fill::before {
  content: "\f5bf";
}

.bi-telephone-x::before {
  content: "\f5c0";
}

.bi-telephone::before {
  content: "\f5c1";
}

.bi-terminal-fill::before {
  content: "\f5c2";
}

.bi-terminal::before {
  content: "\f5c3";
}

.bi-text-center::before {
  content: "\f5c4";
}

.bi-text-indent-left::before {
  content: "\f5c5";
}

.bi-text-indent-right::before {
  content: "\f5c6";
}

.bi-text-left::before {
  content: "\f5c7";
}

.bi-text-paragraph::before {
  content: "\f5c8";
}

.bi-text-right::before {
  content: "\f5c9";
}

.bi-textarea-resize::before {
  content: "\f5ca";
}

.bi-textarea-t::before {
  content: "\f5cb";
}

.bi-textarea::before {
  content: "\f5cc";
}

.bi-thermometer-half::before {
  content: "\f5cd";
}

.bi-thermometer-high::before {
  content: "\f5ce";
}

.bi-thermometer-low::before {
  content: "\f5cf";
}

.bi-thermometer-snow::before {
  content: "\f5d0";
}

.bi-thermometer-sun::before {
  content: "\f5d1";
}

.bi-thermometer::before {
  content: "\f5d2";
}

.bi-three-dots-vertical::before {
  content: "\f5d3";
}

.bi-three-dots::before {
  content: "\f5d4";
}

.bi-toggle-off::before {
  content: "\f5d5";
}

.bi-toggle-on::before {
  content: "\f5d6";
}

.bi-toggle2-off::before {
  content: "\f5d7";
}

.bi-toggle2-on::before {
  content: "\f5d8";
}

.bi-toggles::before {
  content: "\f5d9";
}

.bi-toggles2::before {
  content: "\f5da";
}

.bi-tools::before {
  content: "\f5db";
}

.bi-tornado::before {
  content: "\f5dc";
}

.bi-trash-fill::before {
  content: "\f5dd";
}

.bi-trash::before {
  content: "\f5de";
}

.bi-trash2-fill::before {
  content: "\f5df";
}

.bi-trash2::before {
  content: "\f5e0";
}

.bi-tree-fill::before {
  content: "\f5e1";
}

.bi-tree::before {
  content: "\f5e2";
}

.bi-triangle-fill::before {
  content: "\f5e3";
}

.bi-triangle-half::before {
  content: "\f5e4";
}

.bi-triangle::before {
  content: "\f5e5";
}

.bi-trophy-fill::before {
  content: "\f5e6";
}

.bi-trophy::before {
  content: "\f5e7";
}

.bi-tropical-storm::before {
  content: "\f5e8";
}

.bi-truck-flatbed::before {
  content: "\f5e9";
}

.bi-truck::before {
  content: "\f5ea";
}

.bi-tsunami::before {
  content: "\f5eb";
}

.bi-tv-fill::before {
  content: "\f5ec";
}

.bi-tv::before {
  content: "\f5ed";
}

.bi-twitch::before {
  content: "\f5ee";
}

.bi-twitter::before {
  content: "\f5ef";
}

.bi-type-bold::before {
  content: "\f5f0";
}

.bi-type-h1::before {
  content: "\f5f1";
}

.bi-type-h2::before {
  content: "\f5f2";
}

.bi-type-h3::before {
  content: "\f5f3";
}

.bi-type-italic::before {
  content: "\f5f4";
}

.bi-type-strikethrough::before {
  content: "\f5f5";
}

.bi-type-underline::before {
  content: "\f5f6";
}

.bi-type::before {
  content: "\f5f7";
}

.bi-ui-checks-grid::before {
  content: "\f5f8";
}

.bi-ui-checks::before {
  content: "\f5f9";
}

.bi-ui-radios-grid::before {
  content: "\f5fa";
}

.bi-ui-radios::before {
  content: "\f5fb";
}

.bi-umbrella-fill::before {
  content: "\f5fc";
}

.bi-umbrella::before {
  content: "\f5fd";
}

.bi-union::before {
  content: "\f5fe";
}

.bi-unlock-fill::before {
  content: "\f5ff";
}

.bi-unlock::before {
  content: "\f600";
}

.bi-upc-scan::before {
  content: "\f601";
}

.bi-upc::before {
  content: "\f602";
}

.bi-upload::before {
  content: "\f603";
}

.bi-vector-pen::before {
  content: "\f604";
}

.bi-view-list::before {
  content: "\f605";
}

.bi-view-stacked::before {
  content: "\f606";
}

.bi-vinyl-fill::before {
  content: "\f607";
}

.bi-vinyl::before {
  content: "\f608";
}

.bi-voicemail::before {
  content: "\f609";
}

.bi-volume-down-fill::before {
  content: "\f60a";
}

.bi-volume-down::before {
  content: "\f60b";
}

.bi-volume-mute-fill::before {
  content: "\f60c";
}

.bi-volume-mute::before {
  content: "\f60d";
}

.bi-volume-off-fill::before {
  content: "\f60e";
}

.bi-volume-off::before {
  content: "\f60f";
}

.bi-volume-up-fill::before {
  content: "\f610";
}

.bi-volume-up::before {
  content: "\f611";
}

.bi-vr::before {
  content: "\f612";
}

.bi-wallet-fill::before {
  content: "\f613";
}

.bi-wallet::before {
  content: "\f614";
}

.bi-wallet2::before {
  content: "\f615";
}

.bi-watch::before {
  content: "\f616";
}

.bi-water::before {
  content: "\f617";
}

.bi-whatsapp::before {
  content: "\f618";
}

.bi-wifi-1::before {
  content: "\f619";
}

.bi-wifi-2::before {
  content: "\f61a";
}

.bi-wifi-off::before {
  content: "\f61b";
}

.bi-wifi::before {
  content: "\f61c";
}

.bi-wind::before {
  content: "\f61d";
}

.bi-window-dock::before {
  content: "\f61e";
}

.bi-window-sidebar::before {
  content: "\f61f";
}

.bi-window::before {
  content: "\f620";
}

.bi-wrench::before {
  content: "\f621";
}

.bi-x-circle-fill::before {
  content: "\f622";
}

.bi-x-circle::before {
  content: "\f623";
}

.bi-x-diamond-fill::before {
  content: "\f624";
}

.bi-x-diamond::before {
  content: "\f625";
}

.bi-x-octagon-fill::before {
  content: "\f626";
}

.bi-x-octagon::before {
  content: "\f627";
}

.bi-x-square-fill::before {
  content: "\f628";
}

.bi-x-square::before {
  content: "\f629";
}

.bi-x::before {
  content: "\f62a";
}

.bi-youtube::before {
  content: "\f62b";
}

.bi-zoom-in::before {
  content: "\f62c";
}

.bi-zoom-out::before {
  content: "\f62d";
}

.bi-bank::before {
  content: "\f62e";
}

.bi-bank2::before {
  content: "\f62f";
}

.bi-bell-slash-fill::before {
  content: "\f630";
}

.bi-bell-slash::before {
  content: "\f631";
}

.bi-cash-coin::before {
  content: "\f632";
}

.bi-check-lg::before {
  content: "\f633";
}

.bi-coin::before {
  content: "\f634";
}

.bi-currency-bitcoin::before {
  content: "\f635";
}

.bi-currency-dollar::before {
  content: "\f636";
}

.bi-currency-euro::before {
  content: "\f637";
}

.bi-currency-exchange::before {
  content: "\f638";
}

.bi-currency-pound::before {
  content: "\f639";
}

.bi-currency-yen::before {
  content: "\f63a";
}

.bi-dash-lg::before {
  content: "\f63b";
}

.bi-exclamation-lg::before {
  content: "\f63c";
}

.bi-file-earmark-pdf-fill::before {
  content: "\f63d";
}

.bi-file-earmark-pdf::before {
  content: "\f63e";
}

.bi-file-pdf-fill::before {
  content: "\f63f";
}

.bi-file-pdf::before {
  content: "\f640";
}

.bi-gender-ambiguous::before {
  content: "\f641";
}

.bi-gender-female::before {
  content: "\f642";
}

.bi-gender-male::before {
  content: "\f643";
}

.bi-gender-trans::before {
  content: "\f644";
}

.bi-headset-vr::before {
  content: "\f645";
}

.bi-info-lg::before {
  content: "\f646";
}

.bi-mastodon::before {
  content: "\f647";
}

.bi-messenger::before {
  content: "\f648";
}

.bi-piggy-bank-fill::before {
  content: "\f649";
}

.bi-piggy-bank::before {
  content: "\f64a";
}

.bi-pin-map-fill::before {
  content: "\f64b";
}

.bi-pin-map::before {
  content: "\f64c";
}

.bi-plus-lg::before {
  content: "\f64d";
}

.bi-question-lg::before {
  content: "\f64e";
}

.bi-recycle::before {
  content: "\f64f";
}

.bi-reddit::before {
  content: "\f650";
}

.bi-safe-fill::before {
  content: "\f651";
}

.bi-safe2-fill::before {
  content: "\f652";
}

.bi-safe2::before {
  content: "\f653";
}

.bi-sd-card-fill::before {
  content: "\f654";
}

.bi-sd-card::before {
  content: "\f655";
}

.bi-skype::before {
  content: "\f656";
}

.bi-slash-lg::before {
  content: "\f657";
}

.bi-translate::before {
  content: "\f658";
}

.bi-x-lg::before {
  content: "\f659";
}

.bi-safe::before {
  content: "\f65a";
}

.bi-apple::before {
  content: "\f65b";
}

.bi-microsoft::before {
  content: "\f65d";
}

.bi-windows::before {
  content: "\f65e";
}

.bi-behance::before {
  content: "\f65c";
}

.bi-dribbble::before {
  content: "\f65f";
}

.bi-line::before {
  content: "\f660";
}

.bi-medium::before {
  content: "\f661";
}

.bi-paypal::before {
  content: "\f662";
}

.bi-pinterest::before {
  content: "\f663";
}

.bi-signal::before {
  content: "\f664";
}

.bi-snapchat::before {
  content: "\f665";
}

.bi-spotify::before {
  content: "\f666";
}

.bi-stack-overflow::before {
  content: "\f667";
}

.bi-strava::before {
  content: "\f668";
}

.bi-wordpress::before {
  content: "\f669";
}

.bi-vimeo::before {
  content: "\f66a";
}

.bi-activity::before {
  content: "\f66b";
}

.bi-easel2-fill::before {
  content: "\f66c";
}

.bi-easel2::before {
  content: "\f66d";
}

.bi-easel3-fill::before {
  content: "\f66e";
}

.bi-easel3::before {
  content: "\f66f";
}

.bi-fan::before {
  content: "\f670";
}

.bi-fingerprint::before {
  content: "\f671";
}

.bi-graph-down-arrow::before {
  content: "\f672";
}

.bi-graph-up-arrow::before {
  content: "\f673";
}

.bi-hypnotize::before {
  content: "\f674";
}

.bi-magic::before {
  content: "\f675";
}

.bi-person-rolodex::before {
  content: "\f676";
}

.bi-person-video::before {
  content: "\f677";
}

.bi-person-video2::before {
  content: "\f678";
}

.bi-person-video3::before {
  content: "\f679";
}

.bi-person-workspace::before {
  content: "\f67a";
}

.bi-radioactive::before {
  content: "\f67b";
}

.bi-webcam-fill::before {
  content: "\f67c";
}

.bi-webcam::before {
  content: "\f67d";
}

.bi-yin-yang::before {
  content: "\f67e";
}

.bi-bandaid-fill::before {
  content: "\f680";
}

.bi-bandaid::before {
  content: "\f681";
}

.bi-bluetooth::before {
  content: "\f682";
}

.bi-body-text::before {
  content: "\f683";
}

.bi-boombox::before {
  content: "\f684";
}

.bi-boxes::before {
  content: "\f685";
}

.bi-dpad-fill::before {
  content: "\f686";
}

.bi-dpad::before {
  content: "\f687";
}

.bi-ear-fill::before {
  content: "\f688";
}

.bi-ear::before {
  content: "\f689";
}

.bi-envelope-check-fill::before {
  content: "\f68b";
}

.bi-envelope-check::before {
  content: "\f68c";
}

.bi-envelope-dash-fill::before {
  content: "\f68e";
}

.bi-envelope-dash::before {
  content: "\f68f";
}

.bi-envelope-exclamation-fill::before {
  content: "\f691";
}

.bi-envelope-exclamation::before {
  content: "\f692";
}

.bi-envelope-plus-fill::before {
  content: "\f693";
}

.bi-envelope-plus::before {
  content: "\f694";
}

.bi-envelope-slash-fill::before {
  content: "\f696";
}

.bi-envelope-slash::before {
  content: "\f697";
}

.bi-envelope-x-fill::before {
  content: "\f699";
}

.bi-envelope-x::before {
  content: "\f69a";
}

.bi-explicit-fill::before {
  content: "\f69b";
}

.bi-explicit::before {
  content: "\f69c";
}

.bi-git::before {
  content: "\f69d";
}

.bi-infinity::before {
  content: "\f69e";
}

.bi-list-columns-reverse::before {
  content: "\f69f";
}

.bi-list-columns::before {
  content: "\f6a0";
}

.bi-meta::before {
  content: "\f6a1";
}

.bi-nintendo-switch::before {
  content: "\f6a4";
}

.bi-pc-display-horizontal::before {
  content: "\f6a5";
}

.bi-pc-display::before {
  content: "\f6a6";
}

.bi-pc-horizontal::before {
  content: "\f6a7";
}

.bi-pc::before {
  content: "\f6a8";
}

.bi-playstation::before {
  content: "\f6a9";
}

.bi-plus-slash-minus::before {
  content: "\f6aa";
}

.bi-projector-fill::before {
  content: "\f6ab";
}

.bi-projector::before {
  content: "\f6ac";
}

.bi-qr-code-scan::before {
  content: "\f6ad";
}

.bi-qr-code::before {
  content: "\f6ae";
}

.bi-quora::before {
  content: "\f6af";
}

.bi-quote::before {
  content: "\f6b0";
}

.bi-robot::before {
  content: "\f6b1";
}

.bi-send-check-fill::before {
  content: "\f6b2";
}

.bi-send-check::before {
  content: "\f6b3";
}

.bi-send-dash-fill::before {
  content: "\f6b4";
}

.bi-send-dash::before {
  content: "\f6b5";
}

.bi-send-exclamation-fill::before {
  content: "\f6b7";
}

.bi-send-exclamation::before {
  content: "\f6b8";
}

.bi-send-fill::before {
  content: "\f6b9";
}

.bi-send-plus-fill::before {
  content: "\f6ba";
}

.bi-send-plus::before {
  content: "\f6bb";
}

.bi-send-slash-fill::before {
  content: "\f6bc";
}

.bi-send-slash::before {
  content: "\f6bd";
}

.bi-send-x-fill::before {
  content: "\f6be";
}

.bi-send-x::before {
  content: "\f6bf";
}

.bi-send::before {
  content: "\f6c0";
}

.bi-steam::before {
  content: "\f6c1";
}

.bi-terminal-dash::before {
  content: "\f6c3";
}

.bi-terminal-plus::before {
  content: "\f6c4";
}

.bi-terminal-split::before {
  content: "\f6c5";
}

.bi-ticket-detailed-fill::before {
  content: "\f6c6";
}

.bi-ticket-detailed::before {
  content: "\f6c7";
}

.bi-ticket-fill::before {
  content: "\f6c8";
}

.bi-ticket-perforated-fill::before {
  content: "\f6c9";
}

.bi-ticket-perforated::before {
  content: "\f6ca";
}

.bi-ticket::before {
  content: "\f6cb";
}

.bi-tiktok::before {
  content: "\f6cc";
}

.bi-window-dash::before {
  content: "\f6cd";
}

.bi-window-desktop::before {
  content: "\f6ce";
}

.bi-window-fullscreen::before {
  content: "\f6cf";
}

.bi-window-plus::before {
  content: "\f6d0";
}

.bi-window-split::before {
  content: "\f6d1";
}

.bi-window-stack::before {
  content: "\f6d2";
}

.bi-window-x::before {
  content: "\f6d3";
}

.bi-xbox::before {
  content: "\f6d4";
}

.bi-ethernet::before {
  content: "\f6d5";
}

.bi-hdmi-fill::before {
  content: "\f6d6";
}

.bi-hdmi::before {
  content: "\f6d7";
}

.bi-usb-c-fill::before {
  content: "\f6d8";
}

.bi-usb-c::before {
  content: "\f6d9";
}

.bi-usb-fill::before {
  content: "\f6da";
}

.bi-usb-plug-fill::before {
  content: "\f6db";
}

.bi-usb-plug::before {
  content: "\f6dc";
}

.bi-usb-symbol::before {
  content: "\f6dd";
}

.bi-usb::before {
  content: "\f6de";
}

.bi-boombox-fill::before {
  content: "\f6df";
}

.bi-displayport::before {
  content: "\f6e1";
}

.bi-gpu-card::before {
  content: "\f6e2";
}

.bi-memory::before {
  content: "\f6e3";
}

.bi-modem-fill::before {
  content: "\f6e4";
}

.bi-modem::before {
  content: "\f6e5";
}

.bi-motherboard-fill::before {
  content: "\f6e6";
}

.bi-motherboard::before {
  content: "\f6e7";
}

.bi-optical-audio-fill::before {
  content: "\f6e8";
}

.bi-optical-audio::before {
  content: "\f6e9";
}

.bi-pci-card::before {
  content: "\f6ea";
}

.bi-router-fill::before {
  content: "\f6eb";
}

.bi-router::before {
  content: "\f6ec";
}

.bi-thunderbolt-fill::before {
  content: "\f6ef";
}

.bi-thunderbolt::before {
  content: "\f6f0";
}

.bi-usb-drive-fill::before {
  content: "\f6f1";
}

.bi-usb-drive::before {
  content: "\f6f2";
}

.bi-usb-micro-fill::before {
  content: "\f6f3";
}

.bi-usb-micro::before {
  content: "\f6f4";
}

.bi-usb-mini-fill::before {
  content: "\f6f5";
}

.bi-usb-mini::before {
  content: "\f6f6";
}

.bi-cloud-haze2::before {
  content: "\f6f7";
}

.bi-device-hdd-fill::before {
  content: "\f6f8";
}

.bi-device-hdd::before {
  content: "\f6f9";
}

.bi-device-ssd-fill::before {
  content: "\f6fa";
}

.bi-device-ssd::before {
  content: "\f6fb";
}

.bi-displayport-fill::before {
  content: "\f6fc";
}

.bi-mortarboard-fill::before {
  content: "\f6fd";
}

.bi-mortarboard::before {
  content: "\f6fe";
}

.bi-terminal-x::before {
  content: "\f6ff";
}

.bi-arrow-through-heart-fill::before {
  content: "\f700";
}

.bi-arrow-through-heart::before {
  content: "\f701";
}

.bi-badge-sd-fill::before {
  content: "\f702";
}

.bi-badge-sd::before {
  content: "\f703";
}

.bi-bag-heart-fill::before {
  content: "\f704";
}

.bi-bag-heart::before {
  content: "\f705";
}

.bi-balloon-fill::before {
  content: "\f706";
}

.bi-balloon-heart-fill::before {
  content: "\f707";
}

.bi-balloon-heart::before {
  content: "\f708";
}

.bi-balloon::before {
  content: "\f709";
}

.bi-box2-fill::before {
  content: "\f70a";
}

.bi-box2-heart-fill::before {
  content: "\f70b";
}

.bi-box2-heart::before {
  content: "\f70c";
}

.bi-box2::before {
  content: "\f70d";
}

.bi-braces-asterisk::before {
  content: "\f70e";
}

.bi-calendar-heart-fill::before {
  content: "\f70f";
}

.bi-calendar-heart::before {
  content: "\f710";
}

.bi-calendar2-heart-fill::before {
  content: "\f711";
}

.bi-calendar2-heart::before {
  content: "\f712";
}

.bi-chat-heart-fill::before {
  content: "\f713";
}

.bi-chat-heart::before {
  content: "\f714";
}

.bi-chat-left-heart-fill::before {
  content: "\f715";
}

.bi-chat-left-heart::before {
  content: "\f716";
}

.bi-chat-right-heart-fill::before {
  content: "\f717";
}

.bi-chat-right-heart::before {
  content: "\f718";
}

.bi-chat-square-heart-fill::before {
  content: "\f719";
}

.bi-chat-square-heart::before {
  content: "\f71a";
}

.bi-clipboard-check-fill::before {
  content: "\f71b";
}

.bi-clipboard-data-fill::before {
  content: "\f71c";
}

.bi-clipboard-fill::before {
  content: "\f71d";
}

.bi-clipboard-heart-fill::before {
  content: "\f71e";
}

.bi-clipboard-heart::before {
  content: "\f71f";
}

.bi-clipboard-minus-fill::before {
  content: "\f720";
}

.bi-clipboard-plus-fill::before {
  content: "\f721";
}

.bi-clipboard-pulse::before {
  content: "\f722";
}

.bi-clipboard-x-fill::before {
  content: "\f723";
}

.bi-clipboard2-check-fill::before {
  content: "\f724";
}

.bi-clipboard2-check::before {
  content: "\f725";
}

.bi-clipboard2-data-fill::before {
  content: "\f726";
}

.bi-clipboard2-data::before {
  content: "\f727";
}

.bi-clipboard2-fill::before {
  content: "\f728";
}

.bi-clipboard2-heart-fill::before {
  content: "\f729";
}

.bi-clipboard2-heart::before {
  content: "\f72a";
}

.bi-clipboard2-minus-fill::before {
  content: "\f72b";
}

.bi-clipboard2-minus::before {
  content: "\f72c";
}

.bi-clipboard2-plus-fill::before {
  content: "\f72d";
}

.bi-clipboard2-plus::before {
  content: "\f72e";
}

.bi-clipboard2-pulse-fill::before {
  content: "\f72f";
}

.bi-clipboard2-pulse::before {
  content: "\f730";
}

.bi-clipboard2-x-fill::before {
  content: "\f731";
}

.bi-clipboard2-x::before {
  content: "\f732";
}

.bi-clipboard2::before {
  content: "\f733";
}

.bi-emoji-kiss-fill::before {
  content: "\f734";
}

.bi-emoji-kiss::before {
  content: "\f735";
}

.bi-envelope-heart-fill::before {
  content: "\f736";
}

.bi-envelope-heart::before {
  content: "\f737";
}

.bi-envelope-open-heart-fill::before {
  content: "\f738";
}

.bi-envelope-open-heart::before {
  content: "\f739";
}

.bi-envelope-paper-fill::before {
  content: "\f73a";
}

.bi-envelope-paper-heart-fill::before {
  content: "\f73b";
}

.bi-envelope-paper-heart::before {
  content: "\f73c";
}

.bi-envelope-paper::before {
  content: "\f73d";
}

.bi-filetype-aac::before {
  content: "\f73e";
}

.bi-filetype-ai::before {
  content: "\f73f";
}

.bi-filetype-bmp::before {
  content: "\f740";
}

.bi-filetype-cs::before {
  content: "\f741";
}

.bi-filetype-css::before {
  content: "\f742";
}

.bi-filetype-csv::before {
  content: "\f743";
}

.bi-filetype-doc::before {
  content: "\f744";
}

.bi-filetype-docx::before {
  content: "\f745";
}

.bi-filetype-exe::before {
  content: "\f746";
}

.bi-filetype-gif::before {
  content: "\f747";
}

.bi-filetype-heic::before {
  content: "\f748";
}

.bi-filetype-html::before {
  content: "\f749";
}

.bi-filetype-java::before {
  content: "\f74a";
}

.bi-filetype-jpg::before {
  content: "\f74b";
}

.bi-filetype-js::before {
  content: "\f74c";
}

.bi-filetype-jsx::before {
  content: "\f74d";
}

.bi-filetype-key::before {
  content: "\f74e";
}

.bi-filetype-m4p::before {
  content: "\f74f";
}

.bi-filetype-md::before {
  content: "\f750";
}

.bi-filetype-mdx::before {
  content: "\f751";
}

.bi-filetype-mov::before {
  content: "\f752";
}

.bi-filetype-mp3::before {
  content: "\f753";
}

.bi-filetype-mp4::before {
  content: "\f754";
}

.bi-filetype-otf::before {
  content: "\f755";
}

.bi-filetype-pdf::before {
  content: "\f756";
}

.bi-filetype-php::before {
  content: "\f757";
}

.bi-filetype-png::before {
  content: "\f758";
}

.bi-filetype-ppt::before {
  content: "\f75a";
}

.bi-filetype-psd::before {
  content: "\f75b";
}

.bi-filetype-py::before {
  content: "\f75c";
}

.bi-filetype-raw::before {
  content: "\f75d";
}

.bi-filetype-rb::before {
  content: "\f75e";
}

.bi-filetype-sass::before {
  content: "\f75f";
}

.bi-filetype-scss::before {
  content: "\f760";
}

.bi-filetype-sh::before {
  content: "\f761";
}

.bi-filetype-svg::before {
  content: "\f762";
}

.bi-filetype-tiff::before {
  content: "\f763";
}

.bi-filetype-tsx::before {
  content: "\f764";
}

.bi-filetype-ttf::before {
  content: "\f765";
}

.bi-filetype-txt::before {
  content: "\f766";
}

.bi-filetype-wav::before {
  content: "\f767";
}

.bi-filetype-woff::before {
  content: "\f768";
}

.bi-filetype-xls::before {
  content: "\f76a";
}

.bi-filetype-xml::before {
  content: "\f76b";
}

.bi-filetype-yml::before {
  content: "\f76c";
}

.bi-heart-arrow::before {
  content: "\f76d";
}

.bi-heart-pulse-fill::before {
  content: "\f76e";
}

.bi-heart-pulse::before {
  content: "\f76f";
}

.bi-heartbreak-fill::before {
  content: "\f770";
}

.bi-heartbreak::before {
  content: "\f771";
}

.bi-hearts::before {
  content: "\f772";
}

.bi-hospital-fill::before {
  content: "\f773";
}

.bi-hospital::before {
  content: "\f774";
}

.bi-house-heart-fill::before {
  content: "\f775";
}

.bi-house-heart::before {
  content: "\f776";
}

.bi-incognito::before {
  content: "\f777";
}

.bi-magnet-fill::before {
  content: "\f778";
}

.bi-magnet::before {
  content: "\f779";
}

.bi-person-heart::before {
  content: "\f77a";
}

.bi-person-hearts::before {
  content: "\f77b";
}

.bi-phone-flip::before {
  content: "\f77c";
}

.bi-plugin::before {
  content: "\f77d";
}

.bi-postage-fill::before {
  content: "\f77e";
}

.bi-postage-heart-fill::before {
  content: "\f77f";
}

.bi-postage-heart::before {
  content: "\f780";
}

.bi-postage::before {
  content: "\f781";
}

.bi-postcard-fill::before {
  content: "\f782";
}

.bi-postcard-heart-fill::before {
  content: "\f783";
}

.bi-postcard-heart::before {
  content: "\f784";
}

.bi-postcard::before {
  content: "\f785";
}

.bi-search-heart-fill::before {
  content: "\f786";
}

.bi-search-heart::before {
  content: "\f787";
}

.bi-sliders2-vertical::before {
  content: "\f788";
}

.bi-sliders2::before {
  content: "\f789";
}

.bi-trash3-fill::before {
  content: "\f78a";
}

.bi-trash3::before {
  content: "\f78b";
}

.bi-valentine::before {
  content: "\f78c";
}

.bi-valentine2::before {
  content: "\f78d";
}

.bi-wrench-adjustable-circle-fill::before {
  content: "\f78e";
}

.bi-wrench-adjustable-circle::before {
  content: "\f78f";
}

.bi-wrench-adjustable::before {
  content: "\f790";
}

.bi-filetype-json::before {
  content: "\f791";
}

.bi-filetype-pptx::before {
  content: "\f792";
}

.bi-filetype-xlsx::before {
  content: "\f793";
}

.bi-1-circle-fill::before {
  content: "\f796";
}

.bi-1-circle::before {
  content: "\f797";
}

.bi-1-square-fill::before {
  content: "\f798";
}

.bi-1-square::before {
  content: "\f799";
}

.bi-2-circle-fill::before {
  content: "\f79c";
}

.bi-2-circle::before {
  content: "\f79d";
}

.bi-2-square-fill::before {
  content: "\f79e";
}

.bi-2-square::before {
  content: "\f79f";
}

.bi-3-circle-fill::before {
  content: "\f7a2";
}

.bi-3-circle::before {
  content: "\f7a3";
}

.bi-3-square-fill::before {
  content: "\f7a4";
}

.bi-3-square::before {
  content: "\f7a5";
}

.bi-4-circle-fill::before {
  content: "\f7a8";
}

.bi-4-circle::before {
  content: "\f7a9";
}

.bi-4-square-fill::before {
  content: "\f7aa";
}

.bi-4-square::before {
  content: "\f7ab";
}

.bi-5-circle-fill::before {
  content: "\f7ae";
}

.bi-5-circle::before {
  content: "\f7af";
}

.bi-5-square-fill::before {
  content: "\f7b0";
}

.bi-5-square::before {
  content: "\f7b1";
}

.bi-6-circle-fill::before {
  content: "\f7b4";
}

.bi-6-circle::before {
  content: "\f7b5";
}

.bi-6-square-fill::before {
  content: "\f7b6";
}

.bi-6-square::before {
  content: "\f7b7";
}

.bi-7-circle-fill::before {
  content: "\f7ba";
}

.bi-7-circle::before {
  content: "\f7bb";
}

.bi-7-square-fill::before {
  content: "\f7bc";
}

.bi-7-square::before {
  content: "\f7bd";
}

.bi-8-circle-fill::before {
  content: "\f7c0";
}

.bi-8-circle::before {
  content: "\f7c1";
}

.bi-8-square-fill::before {
  content: "\f7c2";
}

.bi-8-square::before {
  content: "\f7c3";
}

.bi-9-circle-fill::before {
  content: "\f7c6";
}

.bi-9-circle::before {
  content: "\f7c7";
}

.bi-9-square-fill::before {
  content: "\f7c8";
}

.bi-9-square::before {
  content: "\f7c9";
}

.bi-airplane-engines-fill::before {
  content: "\f7ca";
}

.bi-airplane-engines::before {
  content: "\f7cb";
}

.bi-airplane-fill::before {
  content: "\f7cc";
}

.bi-airplane::before {
  content: "\f7cd";
}

.bi-alexa::before {
  content: "\f7ce";
}

.bi-alipay::before {
  content: "\f7cf";
}

.bi-android::before {
  content: "\f7d0";
}

.bi-android2::before {
  content: "\f7d1";
}

.bi-box-fill::before {
  content: "\f7d2";
}

.bi-box-seam-fill::before {
  content: "\f7d3";
}

.bi-browser-chrome::before {
  content: "\f7d4";
}

.bi-browser-edge::before {
  content: "\f7d5";
}

.bi-browser-firefox::before {
  content: "\f7d6";
}

.bi-browser-safari::before {
  content: "\f7d7";
}

.bi-c-circle-fill::before {
  content: "\f7da";
}

.bi-c-circle::before {
  content: "\f7db";
}

.bi-c-square-fill::before {
  content: "\f7dc";
}

.bi-c-square::before {
  content: "\f7dd";
}

.bi-capsule-pill::before {
  content: "\f7de";
}

.bi-capsule::before {
  content: "\f7df";
}

.bi-car-front-fill::before {
  content: "\f7e0";
}

.bi-car-front::before {
  content: "\f7e1";
}

.bi-cassette-fill::before {
  content: "\f7e2";
}

.bi-cassette::before {
  content: "\f7e3";
}

.bi-cc-circle-fill::before {
  content: "\f7e6";
}

.bi-cc-circle::before {
  content: "\f7e7";
}

.bi-cc-square-fill::before {
  content: "\f7e8";
}

.bi-cc-square::before {
  content: "\f7e9";
}

.bi-cup-hot-fill::before {
  content: "\f7ea";
}

.bi-cup-hot::before {
  content: "\f7eb";
}

.bi-currency-rupee::before {
  content: "\f7ec";
}

.bi-dropbox::before {
  content: "\f7ed";
}

.bi-escape::before {
  content: "\f7ee";
}

.bi-fast-forward-btn-fill::before {
  content: "\f7ef";
}

.bi-fast-forward-btn::before {
  content: "\f7f0";
}

.bi-fast-forward-circle-fill::before {
  content: "\f7f1";
}

.bi-fast-forward-circle::before {
  content: "\f7f2";
}

.bi-fast-forward-fill::before {
  content: "\f7f3";
}

.bi-fast-forward::before {
  content: "\f7f4";
}

.bi-filetype-sql::before {
  content: "\f7f5";
}

.bi-fire::before {
  content: "\f7f6";
}

.bi-google-play::before {
  content: "\f7f7";
}

.bi-h-circle-fill::before {
  content: "\f7fa";
}

.bi-h-circle::before {
  content: "\f7fb";
}

.bi-h-square-fill::before {
  content: "\f7fc";
}

.bi-h-square::before {
  content: "\f7fd";
}

.bi-indent::before {
  content: "\f7fe";
}

.bi-lungs-fill::before {
  content: "\f7ff";
}

.bi-lungs::before {
  content: "\f800";
}

.bi-microsoft-teams::before {
  content: "\f801";
}

.bi-p-circle-fill::before {
  content: "\f804";
}

.bi-p-circle::before {
  content: "\f805";
}

.bi-p-square-fill::before {
  content: "\f806";
}

.bi-p-square::before {
  content: "\f807";
}

.bi-pass-fill::before {
  content: "\f808";
}

.bi-pass::before {
  content: "\f809";
}

.bi-prescription::before {
  content: "\f80a";
}

.bi-prescription2::before {
  content: "\f80b";
}

.bi-r-circle-fill::before {
  content: "\f80e";
}

.bi-r-circle::before {
  content: "\f80f";
}

.bi-r-square-fill::before {
  content: "\f810";
}

.bi-r-square::before {
  content: "\f811";
}

.bi-repeat-1::before {
  content: "\f812";
}

.bi-repeat::before {
  content: "\f813";
}

.bi-rewind-btn-fill::before {
  content: "\f814";
}

.bi-rewind-btn::before {
  content: "\f815";
}

.bi-rewind-circle-fill::before {
  content: "\f816";
}

.bi-rewind-circle::before {
  content: "\f817";
}

.bi-rewind-fill::before {
  content: "\f818";
}

.bi-rewind::before {
  content: "\f819";
}

.bi-train-freight-front-fill::before {
  content: "\f81a";
}

.bi-train-freight-front::before {
  content: "\f81b";
}

.bi-train-front-fill::before {
  content: "\f81c";
}

.bi-train-front::before {
  content: "\f81d";
}

.bi-train-lightrail-front-fill::before {
  content: "\f81e";
}

.bi-train-lightrail-front::before {
  content: "\f81f";
}

.bi-truck-front-fill::before {
  content: "\f820";
}

.bi-truck-front::before {
  content: "\f821";
}

.bi-ubuntu::before {
  content: "\f822";
}

.bi-unindent::before {
  content: "\f823";
}

.bi-unity::before {
  content: "\f824";
}

.bi-universal-access-circle::before {
  content: "\f825";
}

.bi-universal-access::before {
  content: "\f826";
}

.bi-virus::before {
  content: "\f827";
}

.bi-virus2::before {
  content: "\f828";
}

.bi-wechat::before {
  content: "\f829";
}

.bi-yelp::before {
  content: "\f82a";
}

.bi-sign-stop-fill::before {
  content: "\f82b";
}

.bi-sign-stop-lights-fill::before {
  content: "\f82c";
}

.bi-sign-stop-lights::before {
  content: "\f82d";
}

.bi-sign-stop::before {
  content: "\f82e";
}

.bi-sign-turn-left-fill::before {
  content: "\f82f";
}

.bi-sign-turn-left::before {
  content: "\f830";
}

.bi-sign-turn-right-fill::before {
  content: "\f831";
}

.bi-sign-turn-right::before {
  content: "\f832";
}

.bi-sign-turn-slight-left-fill::before {
  content: "\f833";
}

.bi-sign-turn-slight-left::before {
  content: "\f834";
}

.bi-sign-turn-slight-right-fill::before {
  content: "\f835";
}

.bi-sign-turn-slight-right::before {
  content: "\f836";
}

.bi-sign-yield-fill::before {
  content: "\f837";
}

.bi-sign-yield::before {
  content: "\f838";
}

.bi-ev-station-fill::before {
  content: "\f839";
}

.bi-ev-station::before {
  content: "\f83a";
}

.bi-fuel-pump-diesel-fill::before {
  content: "\f83b";
}

.bi-fuel-pump-diesel::before {
  content: "\f83c";
}

.bi-fuel-pump-fill::before {
  content: "\f83d";
}

.bi-fuel-pump::before {
  content: "\f83e";
}

.bi-0-circle-fill::before {
  content: "\f83f";
}

.bi-0-circle::before {
  content: "\f840";
}

.bi-0-square-fill::before {
  content: "\f841";
}

.bi-0-square::before {
  content: "\f842";
}

.bi-rocket-fill::before {
  content: "\f843";
}

.bi-rocket-takeoff-fill::before {
  content: "\f844";
}

.bi-rocket-takeoff::before {
  content: "\f845";
}

.bi-rocket::before {
  content: "\f846";
}

.bi-stripe::before {
  content: "\f847";
}

.bi-subscript::before {
  content: "\f848";
}

.bi-superscript::before {
  content: "\f849";
}

.bi-trello::before {
  content: "\f84a";
}

.bi-envelope-at-fill::before {
  content: "\f84b";
}

.bi-envelope-at::before {
  content: "\f84c";
}

.bi-regex::before {
  content: "\f84d";
}

.bi-text-wrap::before {
  content: "\f84e";
}

.bi-sign-dead-end-fill::before {
  content: "\f84f";
}

.bi-sign-dead-end::before {
  content: "\f850";
}

.bi-sign-do-not-enter-fill::before {
  content: "\f851";
}

.bi-sign-do-not-enter::before {
  content: "\f852";
}

.bi-sign-intersection-fill::before {
  content: "\f853";
}

.bi-sign-intersection-side-fill::before {
  content: "\f854";
}

.bi-sign-intersection-side::before {
  content: "\f855";
}

.bi-sign-intersection-t-fill::before {
  content: "\f856";
}

.bi-sign-intersection-t::before {
  content: "\f857";
}

.bi-sign-intersection-y-fill::before {
  content: "\f858";
}

.bi-sign-intersection-y::before {
  content: "\f859";
}

.bi-sign-intersection::before {
  content: "\f85a";
}

.bi-sign-merge-left-fill::before {
  content: "\f85b";
}

.bi-sign-merge-left::before {
  content: "\f85c";
}

.bi-sign-merge-right-fill::before {
  content: "\f85d";
}

.bi-sign-merge-right::before {
  content: "\f85e";
}

.bi-sign-no-left-turn-fill::before {
  content: "\f85f";
}

.bi-sign-no-left-turn::before {
  content: "\f860";
}

.bi-sign-no-parking-fill::before {
  content: "\f861";
}

.bi-sign-no-parking::before {
  content: "\f862";
}

.bi-sign-no-right-turn-fill::before {
  content: "\f863";
}

.bi-sign-no-right-turn::before {
  content: "\f864";
}

.bi-sign-railroad-fill::before {
  content: "\f865";
}

.bi-sign-railroad::before {
  content: "\f866";
}

.bi-building-add::before {
  content: "\f867";
}

.bi-building-check::before {
  content: "\f868";
}

.bi-building-dash::before {
  content: "\f869";
}

.bi-building-down::before {
  content: "\f86a";
}

.bi-building-exclamation::before {
  content: "\f86b";
}

.bi-building-fill-add::before {
  content: "\f86c";
}

.bi-building-fill-check::before {
  content: "\f86d";
}

.bi-building-fill-dash::before {
  content: "\f86e";
}

.bi-building-fill-down::before {
  content: "\f86f";
}

.bi-building-fill-exclamation::before {
  content: "\f870";
}

.bi-building-fill-gear::before {
  content: "\f871";
}

.bi-building-fill-lock::before {
  content: "\f872";
}

.bi-building-fill-slash::before {
  content: "\f873";
}

.bi-building-fill-up::before {
  content: "\f874";
}

.bi-building-fill-x::before {
  content: "\f875";
}

.bi-building-fill::before {
  content: "\f876";
}

.bi-building-gear::before {
  content: "\f877";
}

.bi-building-lock::before {
  content: "\f878";
}

.bi-building-slash::before {
  content: "\f879";
}

.bi-building-up::before {
  content: "\f87a";
}

.bi-building-x::before {
  content: "\f87b";
}

.bi-buildings-fill::before {
  content: "\f87c";
}

.bi-buildings::before {
  content: "\f87d";
}

.bi-bus-front-fill::before {
  content: "\f87e";
}

.bi-bus-front::before {
  content: "\f87f";
}

.bi-ev-front-fill::before {
  content: "\f880";
}

.bi-ev-front::before {
  content: "\f881";
}

.bi-globe-americas::before {
  content: "\f882";
}

.bi-globe-asia-australia::before {
  content: "\f883";
}

.bi-globe-central-south-asia::before {
  content: "\f884";
}

.bi-globe-europe-africa::before {
  content: "\f885";
}

.bi-house-add-fill::before {
  content: "\f886";
}

.bi-house-add::before {
  content: "\f887";
}

.bi-house-check-fill::before {
  content: "\f888";
}

.bi-house-check::before {
  content: "\f889";
}

.bi-house-dash-fill::before {
  content: "\f88a";
}

.bi-house-dash::before {
  content: "\f88b";
}

.bi-house-down-fill::before {
  content: "\f88c";
}

.bi-house-down::before {
  content: "\f88d";
}

.bi-house-exclamation-fill::before {
  content: "\f88e";
}

.bi-house-exclamation::before {
  content: "\f88f";
}

.bi-house-gear-fill::before {
  content: "\f890";
}

.bi-house-gear::before {
  content: "\f891";
}

.bi-house-lock-fill::before {
  content: "\f892";
}

.bi-house-lock::before {
  content: "\f893";
}

.bi-house-slash-fill::before {
  content: "\f894";
}

.bi-house-slash::before {
  content: "\f895";
}

.bi-house-up-fill::before {
  content: "\f896";
}

.bi-house-up::before {
  content: "\f897";
}

.bi-house-x-fill::before {
  content: "\f898";
}

.bi-house-x::before {
  content: "\f899";
}

.bi-person-add::before {
  content: "\f89a";
}

.bi-person-down::before {
  content: "\f89b";
}

.bi-person-exclamation::before {
  content: "\f89c";
}

.bi-person-fill-add::before {
  content: "\f89d";
}

.bi-person-fill-check::before {
  content: "\f89e";
}

.bi-person-fill-dash::before {
  content: "\f89f";
}

.bi-person-fill-down::before {
  content: "\f8a0";
}

.bi-person-fill-exclamation::before {
  content: "\f8a1";
}

.bi-person-fill-gear::before {
  content: "\f8a2";
}

.bi-person-fill-lock::before {
  content: "\f8a3";
}

.bi-person-fill-slash::before {
  content: "\f8a4";
}

.bi-person-fill-up::before {
  content: "\f8a5";
}

.bi-person-fill-x::before {
  content: "\f8a6";
}

.bi-person-gear::before {
  content: "\f8a7";
}

.bi-person-lock::before {
  content: "\f8a8";
}

.bi-person-slash::before {
  content: "\f8a9";
}

.bi-person-up::before {
  content: "\f8aa";
}

.bi-scooter::before {
  content: "\f8ab";
}

.bi-taxi-front-fill::before {
  content: "\f8ac";
}

.bi-taxi-front::before {
  content: "\f8ad";
}

.bi-amd::before {
  content: "\f8ae";
}

.bi-database-add::before {
  content: "\f8af";
}

.bi-database-check::before {
  content: "\f8b0";
}

.bi-database-dash::before {
  content: "\f8b1";
}

.bi-database-down::before {
  content: "\f8b2";
}

.bi-database-exclamation::before {
  content: "\f8b3";
}

.bi-database-fill-add::before {
  content: "\f8b4";
}

.bi-database-fill-check::before {
  content: "\f8b5";
}

.bi-database-fill-dash::before {
  content: "\f8b6";
}

.bi-database-fill-down::before {
  content: "\f8b7";
}

.bi-database-fill-exclamation::before {
  content: "\f8b8";
}

.bi-database-fill-gear::before {
  content: "\f8b9";
}

.bi-database-fill-lock::before {
  content: "\f8ba";
}

.bi-database-fill-slash::before {
  content: "\f8bb";
}

.bi-database-fill-up::before {
  content: "\f8bc";
}

.bi-database-fill-x::before {
  content: "\f8bd";
}

.bi-database-fill::before {
  content: "\f8be";
}

.bi-database-gear::before {
  content: "\f8bf";
}

.bi-database-lock::before {
  content: "\f8c0";
}

.bi-database-slash::before {
  content: "\f8c1";
}

.bi-database-up::before {
  content: "\f8c2";
}

.bi-database-x::before {
  content: "\f8c3";
}

.bi-database::before {
  content: "\f8c4";
}

.bi-houses-fill::before {
  content: "\f8c5";
}

.bi-houses::before {
  content: "\f8c6";
}

.bi-nvidia::before {
  content: "\f8c7";
}

.bi-person-vcard-fill::before {
  content: "\f8c8";
}

.bi-person-vcard::before {
  content: "\f8c9";
}

.bi-sina-weibo::before {
  content: "\f8ca";
}

.bi-tencent-qq::before {
  content: "\f8cb";
}

.bi-wikipedia::before {
  content: "\f8cc";
}

.bi-alphabet-uppercase::before {
  content: "\f2a5";
}

.bi-alphabet::before {
  content: "\f68a";
}

.bi-amazon::before {
  content: "\f68d";
}

.bi-arrows-collapse-vertical::before {
  content: "\f690";
}

.bi-arrows-expand-vertical::before {
  content: "\f695";
}

.bi-arrows-vertical::before {
  content: "\f698";
}

.bi-arrows::before {
  content: "\f6a2";
}

.bi-ban-fill::before {
  content: "\f6a3";
}

.bi-ban::before {
  content: "\f6b6";
}

.bi-bing::before {
  content: "\f6c2";
}

.bi-cake::before {
  content: "\f6e0";
}

.bi-cake2::before {
  content: "\f6ed";
}

.bi-cookie::before {
  content: "\f6ee";
}

.bi-copy::before {
  content: "\f759";
}

.bi-crosshair::before {
  content: "\f769";
}

.bi-crosshair2::before {
  content: "\f794";
}

.bi-emoji-astonished-fill::before {
  content: "\f795";
}

.bi-emoji-astonished::before {
  content: "\f79a";
}

.bi-emoji-grimace-fill::before {
  content: "\f79b";
}

.bi-emoji-grimace::before {
  content: "\f7a0";
}

.bi-emoji-grin-fill::before {
  content: "\f7a1";
}

.bi-emoji-grin::before {
  content: "\f7a6";
}

.bi-emoji-surprise-fill::before {
  content: "\f7a7";
}

.bi-emoji-surprise::before {
  content: "\f7ac";
}

.bi-emoji-tear-fill::before {
  content: "\f7ad";
}

.bi-emoji-tear::before {
  content: "\f7b2";
}

.bi-envelope-arrow-down-fill::before {
  content: "\f7b3";
}

.bi-envelope-arrow-down::before {
  content: "\f7b8";
}

.bi-envelope-arrow-up-fill::before {
  content: "\f7b9";
}

.bi-envelope-arrow-up::before {
  content: "\f7be";
}

.bi-feather::before {
  content: "\f7bf";
}

.bi-feather2::before {
  content: "\f7c4";
}

.bi-floppy-fill::before {
  content: "\f7c5";
}

.bi-floppy::before {
  content: "\f7d8";
}

.bi-floppy2-fill::before {
  content: "\f7d9";
}

.bi-floppy2::before {
  content: "\f7e4";
}

.bi-gitlab::before {
  content: "\f7e5";
}

.bi-highlighter::before {
  content: "\f7f8";
}

.bi-marker-tip::before {
  content: "\f802";
}

.bi-nvme-fill::before {
  content: "\f803";
}

.bi-nvme::before {
  content: "\f80c";
}

.bi-opencollective::before {
  content: "\f80d";
}

.bi-pci-card-network::before {
  content: "\f8cd";
}

.bi-pci-card-sound::before {
  content: "\f8ce";
}

.bi-radar::before {
  content: "\f8cf";
}

.bi-send-arrow-down-fill::before {
  content: "\f8d0";
}

.bi-send-arrow-down::before {
  content: "\f8d1";
}

.bi-send-arrow-up-fill::before {
  content: "\f8d2";
}

.bi-send-arrow-up::before {
  content: "\f8d3";
}

.bi-sim-slash-fill::before {
  content: "\f8d4";
}

.bi-sim-slash::before {
  content: "\f8d5";
}

.bi-sourceforge::before {
  content: "\f8d6";
}

.bi-substack::before {
  content: "\f8d7";
}

.bi-threads-fill::before {
  content: "\f8d8";
}

.bi-threads::before {
  content: "\f8d9";
}

.bi-transparency::before {
  content: "\f8da";
}

.bi-twitter-x::before {
  content: "\f8db";
}

.bi-type-h4::before {
  content: "\f8dc";
}

.bi-type-h5::before {
  content: "\f8dd";
}

.bi-type-h6::before {
  content: "\f8de";
}

.bi-backpack-fill::before {
  content: "\f8df";
}

.bi-backpack::before {
  content: "\f8e0";
}

.bi-backpack2-fill::before {
  content: "\f8e1";
}

.bi-backpack2::before {
  content: "\f8e2";
}

.bi-backpack3-fill::before {
  content: "\f8e3";
}

.bi-backpack3::before {
  content: "\f8e4";
}

.bi-backpack4-fill::before {
  content: "\f8e5";
}

.bi-backpack4::before {
  content: "\f8e6";
}

.bi-brilliance::before {
  content: "\f8e7";
}

.bi-cake-fill::before {
  content: "\f8e8";
}

.bi-cake2-fill::before {
  content: "\f8e9";
}

.bi-duffle-fill::before {
  content: "\f8ea";
}

.bi-duffle::before {
  content: "\f8eb";
}

.bi-exposure::before {
  content: "\f8ec";
}

.bi-gender-neuter::before {
  content: "\f8ed";
}

.bi-highlights::before {
  content: "\f8ee";
}

.bi-luggage-fill::before {
  content: "\f8ef";
}

.bi-luggage::before {
  content: "\f8f0";
}

.bi-mailbox-flag::before {
  content: "\f8f1";
}

.bi-mailbox2-flag::before {
  content: "\f8f2";
}

.bi-noise-reduction::before {
  content: "\f8f3";
}

.bi-passport-fill::before {
  content: "\f8f4";
}

.bi-passport::before {
  content: "\f8f5";
}

.bi-person-arms-up::before {
  content: "\f8f6";
}

.bi-person-raised-hand::before {
  content: "\f8f7";
}

.bi-person-standing-dress::before {
  content: "\f8f8";
}

.bi-person-standing::before {
  content: "\f8f9";
}

.bi-person-walking::before {
  content: "\f8fa";
}

.bi-person-wheelchair::before {
  content: "\f8fb";
}

.bi-shadows::before {
  content: "\f8fc";
}

.bi-suitcase-fill::before {
  content: "\f8fd";
}

.bi-suitcase-lg-fill::before {
  content: "\f8fe";
}

.bi-suitcase-lg::before {
  content: "\f8ff";
}

.bi-suitcase::before {
  content: "豈";
}

.bi-suitcase2-fill::before {
  content: "更";
}

.bi-suitcase2::before {
  content: "車";
}

.bi-vignette::before {
  content: "賈";
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-light: #f5f8ff;
  --bs-dark: #090c0d;
  --bs-primary: #2b3480;
  --bs-primary-700: #1a1f4d;
  --bs-secondary: #1c71a6;
  --bs-info: #3898a7;
  --bs-accent1: #f2790e;
  --bs-accent2: #555d99;
  --bs-accent3: #a2a7af;
  --bs-success: #05a963;
  --bs-warning: #e3cd05;
  --bs-danger: #f31d02;
  --bs-accent1-light: #fffaf5;
  --bs-light-rgb: 245, 248, 255;
  --bs-dark-rgb: 9, 12, 13;
  --bs-primary-rgb: 43, 52, 128;
  --bs-primary-700-rgb: 26, 31, 77;
  --bs-secondary-rgb: 28, 113, 166;
  --bs-info-rgb: 56, 152, 167;
  --bs-accent1-rgb: 242, 121, 14;
  --bs-accent2-rgb: 85, 93, 153;
  --bs-accent3-rgb: 162, 167, 175;
  --bs-success-rgb: 5, 169, 99;
  --bs-warning-rgb: 227, 205, 5;
  --bs-danger-rgb: 243, 29, 2;
  --bs-accent1-light-rgb: 255, 250, 245;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 245, 248, 255;
  --bs-font-sans-serif: "Inter", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.95rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #f5f8ff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #d1d9ee;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-heading-color: ;
  --bs-link-color: #2b3480;
  --bs-link-hover-color: #222a66;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #2b3480;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: #2b3480;
  --bs-dropdown-border-radius: 0.5rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 4px 1rem 0rem rgba(43, 52, 128, 0.2);
  --bs-dropdown-link-color: #2b3480;
  --bs-dropdown-link-hover-color: #272f73;
  --bs-dropdown-link-hover-bg: #f5f8ff;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: calc(1.315rem + 0.78vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 1.9rem;
  }
}

h2 {
  font-size: calc(1.2675rem + 0.21vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 1.425rem;
  }
}

h3 {
  font-size: 1.1875rem;
}

h4 {
  font-size: 0.95rem;
}

h5 {
  font-size: 0.95rem;
}

h6 {
  font-size: 0.95rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: rgba(var(--bs-body-color-rgb), 0.75);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container, .trusts,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container, .trusts {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container, .trusts {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container, .trusts {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container, .trusts {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container, .trusts {
    max-width: 1320px;
  }
}
.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: 0.375rem;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius, 0);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-light {
  --bs-alert-color: #626366;
  --bs-alert-bg: #fdfeff;
  --bs-alert-border-color: #fcfdff;
}
.alert-light .alert-link {
  color: #4e4f52;
}

.alert-dark {
  --bs-alert-color: #050708;
  --bs-alert-bg: #cececf;
  --bs-alert-border-color: #b5b6b6;
}
.alert-dark .alert-link {
  color: #040606;
}

.alert-primary {
  --bs-alert-color: #1a1f4d;
  --bs-alert-bg: #d5d6e6;
  --bs-alert-border-color: #bfc2d9;
}
.alert-primary .alert-link {
  color: #15193e;
}

.alert-primary-700 {
  --bs-alert-color: #10132e;
  --bs-alert-bg: #d1d2db;
  --bs-alert-border-color: #babcca;
}
.alert-primary-700 .alert-link {
  color: #0d0f25;
}

.alert-secondary {
  --bs-alert-color: #114464;
  --bs-alert-bg: #d2e3ed;
  --bs-alert-border-color: #bbd4e4;
}
.alert-secondary .alert-link {
  color: #0e3650;
}

.alert-info {
  --bs-alert-color: #225b64;
  --bs-alert-bg: #d7eaed;
  --bs-alert-border-color: #c3e0e5;
}
.alert-info .alert-link {
  color: #1b4950;
}

.alert-accent1 {
  --bs-alert-color: #914908;
  --bs-alert-bg: #fce4cf;
  --bs-alert-border-color: #fbd7b7;
}
.alert-accent1 .alert-link {
  color: #743a06;
}

.alert-accent2 {
  --bs-alert-color: #33385c;
  --bs-alert-bg: #dddfeb;
  --bs-alert-border-color: #cccee0;
}
.alert-accent2 .alert-link {
  color: #292d4a;
}

.alert-accent3 {
  --bs-alert-color: #616469;
  --bs-alert-bg: #ecedef;
  --bs-alert-border-color: #e3e5e7;
}
.alert-accent3 .alert-link {
  color: #4e5054;
}

.alert-success {
  --bs-alert-color: #03653b;
  --bs-alert-bg: #cdeee0;
  --bs-alert-border-color: #b4e5d0;
}
.alert-success .alert-link {
  color: #02512f;
}

.alert-warning {
  --bs-alert-color: #5b5202;
  --bs-alert-bg: #f9f5cd;
  --bs-alert-border-color: #f7f0b4;
}
.alert-warning .alert-link {
  color: #494202;
}

.alert-danger {
  --bs-alert-color: #921101;
  --bs-alert-bg: #fdd2cc;
  --bs-alert-border-color: #fbbbb3;
}
.alert-danger .alert-link {
  color: #750e01;
}

.alert-accent1-light {
  --bs-alert-color: #666462;
  --bs-alert-bg: #fffefd;
  --bs-alert-border-color: #fffefc;
}
.alert-accent1-light .alert-link {
  color: #52504e;
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: 0.375rem;
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius, 0);
}
.badge:empty {
  display: none;
}

.btn .badge, .services-box__action .badge {
  position: relative;
  top: -1px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.grid {
  display: grid;
  grid-template-rows: repeat(var(--bs-rows, 1), 1fr);
  grid-template-columns: repeat(var(--bs-columns, 12), 1fr);
  gap: var(--bs-gap, 1.5rem);
}
.grid .g-col-1 {
  grid-column: auto/span 1;
}
.grid .g-col-2 {
  grid-column: auto/span 2;
}
.grid .g-col-3 {
  grid-column: auto/span 3;
}
.grid .g-col-4 {
  grid-column: auto/span 4;
}
.grid .g-col-5 {
  grid-column: auto/span 5;
}
.grid .g-col-6 {
  grid-column: auto/span 6;
}
.grid .g-col-7 {
  grid-column: auto/span 7;
}
.grid .g-col-8 {
  grid-column: auto/span 8;
}
.grid .g-col-9 {
  grid-column: auto/span 9;
}
.grid .g-col-10 {
  grid-column: auto/span 10;
}
.grid .g-col-11 {
  grid-column: auto/span 11;
}
.grid .g-col-12 {
  grid-column: auto/span 12;
}
.grid .g-start-1 {
  grid-column-start: 1;
}
.grid .g-start-2 {
  grid-column-start: 2;
}
.grid .g-start-3 {
  grid-column-start: 3;
}
.grid .g-start-4 {
  grid-column-start: 4;
}
.grid .g-start-5 {
  grid-column-start: 5;
}
.grid .g-start-6 {
  grid-column-start: 6;
}
.grid .g-start-7 {
  grid-column-start: 7;
}
.grid .g-start-8 {
  grid-column-start: 8;
}
.grid .g-start-9 {
  grid-column-start: 9;
}
.grid .g-start-10 {
  grid-column-start: 10;
}
.grid .g-start-11 {
  grid-column-start: 11;
}
@media (min-width: 576px) {
  .grid .g-col-sm-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-sm-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-sm-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-sm-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-sm-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-sm-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-sm-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-sm-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-sm-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-sm-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-sm-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-sm-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-sm-1 {
    grid-column-start: 1;
  }
  .grid .g-start-sm-2 {
    grid-column-start: 2;
  }
  .grid .g-start-sm-3 {
    grid-column-start: 3;
  }
  .grid .g-start-sm-4 {
    grid-column-start: 4;
  }
  .grid .g-start-sm-5 {
    grid-column-start: 5;
  }
  .grid .g-start-sm-6 {
    grid-column-start: 6;
  }
  .grid .g-start-sm-7 {
    grid-column-start: 7;
  }
  .grid .g-start-sm-8 {
    grid-column-start: 8;
  }
  .grid .g-start-sm-9 {
    grid-column-start: 9;
  }
  .grid .g-start-sm-10 {
    grid-column-start: 10;
  }
  .grid .g-start-sm-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 768px) {
  .grid .g-col-md-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-md-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-md-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-md-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-md-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-md-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-md-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-md-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-md-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-md-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-md-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-md-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-md-1 {
    grid-column-start: 1;
  }
  .grid .g-start-md-2 {
    grid-column-start: 2;
  }
  .grid .g-start-md-3 {
    grid-column-start: 3;
  }
  .grid .g-start-md-4 {
    grid-column-start: 4;
  }
  .grid .g-start-md-5 {
    grid-column-start: 5;
  }
  .grid .g-start-md-6 {
    grid-column-start: 6;
  }
  .grid .g-start-md-7 {
    grid-column-start: 7;
  }
  .grid .g-start-md-8 {
    grid-column-start: 8;
  }
  .grid .g-start-md-9 {
    grid-column-start: 9;
  }
  .grid .g-start-md-10 {
    grid-column-start: 10;
  }
  .grid .g-start-md-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 992px) {
  .grid .g-col-lg-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-lg-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-lg-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-lg-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-lg-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-lg-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-lg-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-lg-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-lg-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-lg-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-lg-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-lg-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-lg-1 {
    grid-column-start: 1;
  }
  .grid .g-start-lg-2 {
    grid-column-start: 2;
  }
  .grid .g-start-lg-3 {
    grid-column-start: 3;
  }
  .grid .g-start-lg-4 {
    grid-column-start: 4;
  }
  .grid .g-start-lg-5 {
    grid-column-start: 5;
  }
  .grid .g-start-lg-6 {
    grid-column-start: 6;
  }
  .grid .g-start-lg-7 {
    grid-column-start: 7;
  }
  .grid .g-start-lg-8 {
    grid-column-start: 8;
  }
  .grid .g-start-lg-9 {
    grid-column-start: 9;
  }
  .grid .g-start-lg-10 {
    grid-column-start: 10;
  }
  .grid .g-start-lg-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1200px) {
  .grid .g-col-xl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xl-11 {
    grid-column-start: 11;
  }
}
@media (min-width: 1400px) {
  .grid .g-col-xxl-1 {
    grid-column: auto/span 1;
  }
  .grid .g-col-xxl-2 {
    grid-column: auto/span 2;
  }
  .grid .g-col-xxl-3 {
    grid-column: auto/span 3;
  }
  .grid .g-col-xxl-4 {
    grid-column: auto/span 4;
  }
  .grid .g-col-xxl-5 {
    grid-column: auto/span 5;
  }
  .grid .g-col-xxl-6 {
    grid-column: auto/span 6;
  }
  .grid .g-col-xxl-7 {
    grid-column: auto/span 7;
  }
  .grid .g-col-xxl-8 {
    grid-column: auto/span 8;
  }
  .grid .g-col-xxl-9 {
    grid-column: auto/span 9;
  }
  .grid .g-col-xxl-10 {
    grid-column: auto/span 10;
  }
  .grid .g-col-xxl-11 {
    grid-column: auto/span 11;
  }
  .grid .g-col-xxl-12 {
    grid-column: auto/span 12;
  }
  .grid .g-start-xxl-1 {
    grid-column-start: 1;
  }
  .grid .g-start-xxl-2 {
    grid-column-start: 2;
  }
  .grid .g-start-xxl-3 {
    grid-column-start: 3;
  }
  .grid .g-start-xxl-4 {
    grid-column-start: 4;
  }
  .grid .g-start-xxl-5 {
    grid-column-start: 5;
  }
  .grid .g-start-xxl-6 {
    grid-column-start: 6;
  }
  .grid .g-start-xxl-7 {
    grid-column-start: 7;
  }
  .grid .g-start-xxl-8 {
    grid-column-start: 8;
  }
  .grid .g-start-xxl-9 {
    grid-column-start: 9;
  }
  .grid .g-start-xxl-10 {
    grid-column-start: 10;
  }
  .grid .g-start-xxl-11 {
    grid-column-start: 11;
  }
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11, .team-member {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12, .product-feature--small .product-feature__screens, .product-feature--small .product-feature__content {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(245, 248, 255, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(9, 12, 13, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(43, 52, 128, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-primary-700 {
  color: #fff !important;
  background-color: RGBA(26, 31, 77, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(28, 113, 166, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(56, 152, 167, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-accent1 {
  color: #000 !important;
  background-color: RGBA(242, 121, 14, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-accent2 {
  color: #fff !important;
  background-color: RGBA(85, 93, 153, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-accent3 {
  color: #000 !important;
  background-color: RGBA(162, 167, 175, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(5, 169, 99, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(227, 205, 5, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(243, 29, 2, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-accent1-light {
  color: #000 !important;
  background-color: RGBA(255, 250, 245, var(--bs-bg-opacity, 1)) !important;
}

.link-light {
  color: #f5f8ff !important;
}
.link-light:hover, .link-light:focus {
  color: #f7f9ff !important;
}

.link-dark {
  color: #090c0d !important;
}
.link-dark:hover, .link-dark:focus {
  color: #070a0a !important;
}

.link-primary {
  color: #2b3480 !important;
}
.link-primary:hover, .link-primary:focus {
  color: #222a66 !important;
}

.link-primary-700 {
  color: #1a1f4d !important;
}
.link-primary-700:hover, .link-primary-700:focus {
  color: #15193e !important;
}

.link-secondary {
  color: #1c71a6 !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: #165a85 !important;
}

.link-info {
  color: #3898a7 !important;
}
.link-info:hover, .link-info:focus {
  color: #60adb9 !important;
}

.link-accent1 {
  color: #f2790e !important;
}
.link-accent1:hover, .link-accent1:focus {
  color: #f5943e !important;
}

.link-accent2 {
  color: #555d99 !important;
}
.link-accent2:hover, .link-accent2:focus {
  color: #444a7a !important;
}

.link-accent3 {
  color: #a2a7af !important;
}
.link-accent3:hover, .link-accent3:focus {
  color: #b5b9bf !important;
}

.link-success {
  color: #05a963 !important;
}
.link-success:hover, .link-success:focus {
  color: #37ba82 !important;
}

.link-warning {
  color: #e3cd05 !important;
}
.link-warning:hover, .link-warning:focus {
  color: #e9d737 !important;
}

.link-danger {
  color: #f31d02 !important;
}
.link-danger:hover, .link-danger:focus {
  color: #f54a35 !important;
}

.link-accent1-light {
  color: #fffaf5 !important;
}
.link-accent1-light:hover, .link-accent1-light:focus {
  color: #fffbf7 !important;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top, .side-panel-modal__header {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 0.95rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: rgba(var(--bs-body-color-rgb), 0.75);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #f5f8ff;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 0.95rem;
  border-radius: 0.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.5rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.375rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 2.25rem 0.5rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.5rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check[disabled] + .services-box__action, .btn-check:disabled + .btn, .btn-check:disabled + .services-box__action {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn, .input-group .services-box__action {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus, .input-group .services-box__action:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #f5f8ff;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.input-group-lg > .services-box__action {
  padding: 0.5rem 1rem;
  font-size: 0.95rem;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.input-group-sm > .services-box__action {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.25rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn, .services-box__action {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn, .services-box__action {
    transition: none;
  }
}
.btn:hover, .services-box__action:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check:focus + .btn, .btn-check:focus + .services-box__action, .btn:focus, .services-box__action:focus {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, .btn-check:checked + .services-box__action, .btn-check:active + .btn, .btn-check:active + .services-box__action, .btn:active, .services-box__action:active, .btn.active, .active.services-box__action, .btn.show, .show.services-box__action {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus, .btn-check:checked + .services-box__action:focus, .btn-check:active + .btn:focus, .btn-check:active + .services-box__action:focus, .btn:active:focus, .services-box__action:active:focus, .btn.active:focus, .active.services-box__action:focus, .btn.show:focus, .show.services-box__action:focus {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .services-box__action:disabled, .btn.disabled, .disabled.services-box__action, fieldset:disabled .btn, fieldset:disabled .services-box__action {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f5f8ff;
  --bs-btn-border-color: #f5f8ff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f7f9ff;
  --bs-btn-hover-border-color: #f6f9ff;
  --bs-btn-focus-shadow-rgb: 208, 211, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f7f9ff;
  --bs-btn-active-border-color: #f6f9ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f5f8ff;
  --bs-btn-disabled-border-color: #f5f8ff;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #090c0d;
  --bs-btn-border-color: #090c0d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #080a0b;
  --bs-btn-hover-border-color: #070a0a;
  --bs-btn-focus-shadow-rgb: 46, 48, 49;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #070a0a;
  --bs-btn-active-border-color: #07090a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #090c0d;
  --bs-btn-disabled-border-color: #090c0d;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2b3480;
  --bs-btn-border-color: #2b3480;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #252c6d;
  --bs-btn-hover-border-color: #222a66;
  --bs-btn-focus-shadow-rgb: 75, 82, 147;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #222a66;
  --bs-btn-active-border-color: #202760;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2b3480;
  --bs-btn-disabled-border-color: #2b3480;
}

.btn-primary-700 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1a1f4d;
  --bs-btn-border-color: #1a1f4d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #161a41;
  --bs-btn-hover-border-color: #15193e;
  --bs-btn-focus-shadow-rgb: 60, 65, 104;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #15193e;
  --bs-btn-active-border-color: #14173a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1a1f4d;
  --bs-btn-disabled-border-color: #1a1f4d;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1c71a6;
  --bs-btn-border-color: #1c71a6;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #18608d;
  --bs-btn-hover-border-color: #165a85;
  --bs-btn-focus-shadow-rgb: 62, 134, 179;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #165a85;
  --bs-btn-active-border-color: #15557d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1c71a6;
  --bs-btn-disabled-border-color: #1c71a6;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #3898a7;
  --bs-btn-border-color: #3898a7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #56a7b4;
  --bs-btn-hover-border-color: #4ca2b0;
  --bs-btn-focus-shadow-rgb: 48, 129, 142;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #60adb9;
  --bs-btn-active-border-color: #4ca2b0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #3898a7;
  --bs-btn-disabled-border-color: #3898a7;
}

.btn-accent1 {
  --bs-btn-color: #000;
  --bs-btn-bg: #f2790e;
  --bs-btn-border-color: #f2790e;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f48d32;
  --bs-btn-hover-border-color: #f38626;
  --bs-btn-focus-shadow-rgb: 206, 103, 12;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5943e;
  --bs-btn-active-border-color: #f38626;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f2790e;
  --bs-btn-disabled-border-color: #f2790e;
}

.btn-accent2 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #555d99;
  --bs-btn-border-color: #555d99;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #484f82;
  --bs-btn-hover-border-color: #444a7a;
  --bs-btn-focus-shadow-rgb: 111, 117, 168;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #444a7a;
  --bs-btn-active-border-color: #404673;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #555d99;
  --bs-btn-disabled-border-color: #555d99;
}

.btn-accent3 {
  --bs-btn-color: #000;
  --bs-btn-bg: #a2a7af;
  --bs-btn-border-color: #a2a7af;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #b0b4bb;
  --bs-btn-hover-border-color: #abb0b7;
  --bs-btn-focus-shadow-rgb: 138, 142, 149;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b5b9bf;
  --bs-btn-active-border-color: #abb0b7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #a2a7af;
  --bs-btn-disabled-border-color: #a2a7af;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #05a963;
  --bs-btn-border-color: #05a963;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #2bb67a;
  --bs-btn-hover-border-color: #1eb273;
  --bs-btn-focus-shadow-rgb: 4, 144, 84;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #37ba82;
  --bs-btn-active-border-color: #1eb273;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #05a963;
  --bs-btn-disabled-border-color: #05a963;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #e3cd05;
  --bs-btn-border-color: #e3cd05;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e7d52b;
  --bs-btn-hover-border-color: #e6d21e;
  --bs-btn-focus-shadow-rgb: 193, 174, 4;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e9d737;
  --bs-btn-active-border-color: #e6d21e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e3cd05;
  --bs-btn-disabled-border-color: #e3cd05;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #f31d02;
  --bs-btn-border-color: #f31d02;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f53f28;
  --bs-btn-hover-border-color: #f4341b;
  --bs-btn-focus-shadow-rgb: 207, 25, 2;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f54a35;
  --bs-btn-active-border-color: #f4341b;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f31d02;
  --bs-btn-disabled-border-color: #f31d02;
}

.btn-accent1-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #fffaf5;
  --bs-btn-border-color: #fffaf5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fffbf7;
  --bs-btn-hover-border-color: #fffbf6;
  --bs-btn-focus-shadow-rgb: 217, 213, 208;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fffbf7;
  --bs-btn-active-border-color: #fffbf6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fffaf5;
  --bs-btn-disabled-border-color: #fffaf5;
}

.btn-outline-light {
  --bs-btn-color: #f5f8ff;
  --bs-btn-border-color: #f5f8ff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f5f8ff;
  --bs-btn-hover-border-color: #f5f8ff;
  --bs-btn-focus-shadow-rgb: 245, 248, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5f8ff;
  --bs-btn-active-border-color: #f5f8ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f5f8ff;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #090c0d;
  --bs-btn-border-color: #090c0d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #090c0d;
  --bs-btn-hover-border-color: #090c0d;
  --bs-btn-focus-shadow-rgb: 9, 12, 13;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #090c0d;
  --bs-btn-active-border-color: #090c0d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #090c0d;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-primary {
  --bs-btn-color: #2b3480;
  --bs-btn-border-color: #2b3480;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2b3480;
  --bs-btn-hover-border-color: #2b3480;
  --bs-btn-focus-shadow-rgb: 43, 52, 128;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2b3480;
  --bs-btn-active-border-color: #2b3480;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2b3480;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-primary-700 {
  --bs-btn-color: #1a1f4d;
  --bs-btn-border-color: #1a1f4d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1a1f4d;
  --bs-btn-hover-border-color: #1a1f4d;
  --bs-btn-focus-shadow-rgb: 26, 31, 77;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1a1f4d;
  --bs-btn-active-border-color: #1a1f4d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1a1f4d;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #1c71a6;
  --bs-btn-border-color: #1c71a6;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1c71a6;
  --bs-btn-hover-border-color: #1c71a6;
  --bs-btn-focus-shadow-rgb: 28, 113, 166;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1c71a6;
  --bs-btn-active-border-color: #1c71a6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #1c71a6;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #3898a7;
  --bs-btn-border-color: #3898a7;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3898a7;
  --bs-btn-hover-border-color: #3898a7;
  --bs-btn-focus-shadow-rgb: 56, 152, 167;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3898a7;
  --bs-btn-active-border-color: #3898a7;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3898a7;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-accent1 {
  --bs-btn-color: #f2790e;
  --bs-btn-border-color: #f2790e;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f2790e;
  --bs-btn-hover-border-color: #f2790e;
  --bs-btn-focus-shadow-rgb: 242, 121, 14;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f2790e;
  --bs-btn-active-border-color: #f2790e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f2790e;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-accent2 {
  --bs-btn-color: #555d99;
  --bs-btn-border-color: #555d99;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #555d99;
  --bs-btn-hover-border-color: #555d99;
  --bs-btn-focus-shadow-rgb: 85, 93, 153;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #555d99;
  --bs-btn-active-border-color: #555d99;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #555d99;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-accent3 {
  --bs-btn-color: #a2a7af;
  --bs-btn-border-color: #a2a7af;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #a2a7af;
  --bs-btn-hover-border-color: #a2a7af;
  --bs-btn-focus-shadow-rgb: 162, 167, 175;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #a2a7af;
  --bs-btn-active-border-color: #a2a7af;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #a2a7af;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #05a963;
  --bs-btn-border-color: #05a963;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #05a963;
  --bs-btn-hover-border-color: #05a963;
  --bs-btn-focus-shadow-rgb: 5, 169, 99;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #05a963;
  --bs-btn-active-border-color: #05a963;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #05a963;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #e3cd05;
  --bs-btn-border-color: #e3cd05;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e3cd05;
  --bs-btn-hover-border-color: #e3cd05;
  --bs-btn-focus-shadow-rgb: 227, 205, 5;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e3cd05;
  --bs-btn-active-border-color: #e3cd05;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e3cd05;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #f31d02;
  --bs-btn-border-color: #f31d02;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f31d02;
  --bs-btn-hover-border-color: #f31d02;
  --bs-btn-focus-shadow-rgb: 243, 29, 2;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f31d02;
  --bs-btn-active-border-color: #f31d02;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f31d02;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-outline-accent1-light {
  --bs-btn-color: #fffaf5;
  --bs-btn-border-color: #fffaf5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fffaf5;
  --bs-btn-hover-border-color: #fffaf5;
  --bs-btn-focus-shadow-rgb: 255, 250, 245;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fffaf5;
  --bs-btn-active-border-color: #fffaf5;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fffaf5;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  text-decoration: underline;
}
.btn-lg {
  --bs-btn-padding-y: 0.75rem;
  --bs-btn-padding-x: 1.25rem;
  --bs-btn-font-size: 0.95rem;
  --bs-btn-border-radius: 0.5rem;
}

.btn-sm, .services-box__action {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 0.25rem;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: #6c757d;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: #dee2e6;
  --bs-nav-tabs-border-radius: 0.375rem;
  --bs-nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --bs-nav-tabs-link-active-color: #495057;
  --bs-nav-tabs-link-active-bg: #fff;
  --bs-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(var(--bs-nav-tabs-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: 0.375rem;
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #2b3480;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before, .placeholder.services-box__action::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.list-group {
  --bs-list-group-color: #212529;
  --bs-list-group-bg: #fff;
  --bs-list-group-border-color: rgba(0, 0, 0, 0.125);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: 0.375rem;
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: #495057;
  --bs-list-group-action-hover-color: #495057;
  --bs-list-group-action-hover-bg: #f8f9fa;
  --bs-list-group-action-active-color: #212529;
  --bs-list-group-action-active-bg: #e9ecef;
  --bs-list-group-disabled-color: #6c757d;
  --bs-list-group-disabled-bg: #fff;
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(var(--bs-list-group-border-width) * -1);
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(var(--bs-list-group-border-width) * -1);
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(var(--bs-list-group-border-width) * -1);
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-light {
  color: #626366;
  background-color: #fdfeff;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #626366;
  background-color: #e4e5e6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #626366;
  border-color: #626366;
}

.list-group-item-dark {
  color: #050708;
  background-color: #cececf;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #050708;
  background-color: #b9b9ba;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #050708;
  border-color: #050708;
}

.list-group-item-primary {
  color: #1a1f4d;
  background-color: #d5d6e6;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #1a1f4d;
  background-color: #c0c1cf;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #1a1f4d;
  border-color: #1a1f4d;
}

.list-group-item-primary-700 {
  color: #10132e;
  background-color: #d1d2db;
}
.list-group-item-primary-700.list-group-item-action:hover, .list-group-item-primary-700.list-group-item-action:focus {
  color: #10132e;
  background-color: #bcbdc5;
}
.list-group-item-primary-700.list-group-item-action.active {
  color: #fff;
  background-color: #10132e;
  border-color: #10132e;
}

.list-group-item-secondary {
  color: #114464;
  background-color: #d2e3ed;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #114464;
  background-color: #bdccd5;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #114464;
  border-color: #114464;
}

.list-group-item-info {
  color: #225b64;
  background-color: #d7eaed;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #225b64;
  background-color: #c2d3d5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #225b64;
  border-color: #225b64;
}

.list-group-item-accent1 {
  color: #914908;
  background-color: #fce4cf;
}
.list-group-item-accent1.list-group-item-action:hover, .list-group-item-accent1.list-group-item-action:focus {
  color: #914908;
  background-color: #e3cdba;
}
.list-group-item-accent1.list-group-item-action.active {
  color: #fff;
  background-color: #914908;
  border-color: #914908;
}

.list-group-item-accent2 {
  color: #33385c;
  background-color: #dddfeb;
}
.list-group-item-accent2.list-group-item-action:hover, .list-group-item-accent2.list-group-item-action:focus {
  color: #33385c;
  background-color: #c7c9d4;
}
.list-group-item-accent2.list-group-item-action.active {
  color: #fff;
  background-color: #33385c;
  border-color: #33385c;
}

.list-group-item-accent3 {
  color: #616469;
  background-color: #ecedef;
}
.list-group-item-accent3.list-group-item-action:hover, .list-group-item-accent3.list-group-item-action:focus {
  color: #616469;
  background-color: #d4d5d7;
}
.list-group-item-accent3.list-group-item-action.active {
  color: #fff;
  background-color: #616469;
  border-color: #616469;
}

.list-group-item-success {
  color: #03653b;
  background-color: #cdeee0;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #03653b;
  background-color: #b9d6ca;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #03653b;
  border-color: #03653b;
}

.list-group-item-warning {
  color: #5b5202;
  background-color: #f9f5cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #5b5202;
  background-color: #e0ddb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #5b5202;
  border-color: #5b5202;
}

.list-group-item-danger {
  color: #921101;
  background-color: #fdd2cc;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #921101;
  background-color: #e4bdb8;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #921101;
  border-color: #921101;
}

.list-group-item-accent1-light {
  color: #666462;
  background-color: #fffefd;
}
.list-group-item-accent1-light.list-group-item-action:hover, .list-group-item-accent1-light.list-group-item-action:focus {
  color: #666462;
  background-color: #e6e5e4;
}
.list-group-item-accent1-light.list-group-item-action.active {
  color: #fff;
  background-color: #666462;
  border-color: #666462;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50, .side-panel__screen {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block, .pricing-table__feature-name {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .team-member, .side-panel-modal__footer, .side-panel-modal__header, .chart-box__header, .box__header, .card-number, .box, .topbar__box {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky, .side-panel-modal__footer {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0, .side-panel-modal__footer {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border, .timeline-step__content {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom, .pricing-table__row--last-of-section > div, .side-panel-modal__header, .box__tips {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start, .pricing-table__row > div:not(:first-child) {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-primary, .side-panel-modal__header, .box__tips {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-700 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-700-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-accent1 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-accent1-rgb), var(--bs-border-opacity)) !important;
}

.border-accent2 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-accent2-rgb), var(--bs-border-opacity)) !important;
}

.border-accent3 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-accent3-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-accent1-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-accent1-light-rgb), var(--bs-border-opacity)) !important;
}

.border-white, .pricing-table__row--last-of-section > div, .pricing-table__row > div:not(:first-child) {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10, .side-panel-modal__header, .box__tips {
  --bs-border-opacity: 0.1;
}

.border-opacity-25, .pricing-table__row--last-of-section > div, .pricing-table__row > div:not(:first-child) {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100, .pricing-table__product {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100, .chart-box, .card-number {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column, .team-member, .card-number, .box, .topbar__box {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1, .side-panel-modal__footer {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end, .side-panel-modal__footer {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between, .side-panel-modal__header, .chart-box__header, .box__header {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center, .team-member, .side-panel-modal__header {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto, .team-member {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2, .btn--big-selector i {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3, .pricing-table-calculator, .chart-box__header, .box__header {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5, .box__footer--light {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0, .topbar__menu li, .chart-box__header--small {
  margin-bottom: 0 !important;
}

.mb-1, .menu ul li, .property {
  margin-bottom: 0.25rem !important;
}

.mb-2, .pricing-table-calculator {
  margin-bottom: 0.5rem !important;
}

.mb-3, .topbar-main-dropdown {
  margin-bottom: 1rem !important;
}

.mb-4, .chart-box__header, .box__header, .box__tips {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4, .topbar-main-dropdown {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4, .side-panel-modal__footer, .box__footer--light {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5, .box__footer--light {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3, .timeline-step__content, .pricing-table-calculator {
  padding: 1rem !important;
}

.p-4, .testimonial, .team-member, .pricing-table__header, .pricing-table__footer, .chart-box, .box__footer--light {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4, .side-panel-modal__footer, .side-panel-modal__header, .box__tips {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3, .pricing-table__header, .side-panel-modal__footer, .side-panel-modal__header, .box__tips {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2, .btn--big-selector {
  padding-bottom: 0.5rem !important;
}

.pb-3, .pricing-table__feature-included, .pricing-table__feature-name, .pricing-table__row--last-of-section > div:first-child {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3, .pricing-table__feature-name {
  padding-left: 1rem !important;
}

.ps-4, .pricing-table__feature-included {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4, .side-panel-modal__header {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5, .chart-box-title__resource, .box-title__resource, .chart-box__header--small .chart-box__title {
  font-size: 1.25rem !important;
}

.fs-6, .side-panel-modal__resize-btn, .chart-box-title span:not(.chart-box-title__resource):not(.box-title__resource), .box-title span:not(.chart-box-title__resource):not(.box-title__resource) {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal, .pricing-table__header, .side-panel-modal__resize-btn {
  font-weight: 400 !important;
}

.fw-bold, .property__name--strong {
  font-weight: 700 !important;
}

.fw-semibold, .side-panel-modal__header {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center, .pricing-table__footer {
  text-align: center !important;
}

.text-decoration-none, .box__footer--light a {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-primary, .card-number__label, .card-number__number {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-primary-700 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-700-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-accent1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-accent1-rgb), var(--bs-text-opacity)) !important;
}

.text-accent2 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-accent2-rgb), var(--bs-text-opacity)) !important;
}

.text-accent3 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-accent3-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-accent1-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-accent1-light-rgb), var(--bs-text-opacity)) !important;
}

.text-black, .property__name {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white, .pricing-table {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), 0.75) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75, .property__name, .card-number__label {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-light, .topbar__menu-link:hover, .box__tips {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary, .pricing-table {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-primary-700 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-700-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-accent1 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-accent1-rgb), var(--bs-bg-opacity)) !important;
}

.bg-accent2 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-accent2-rgb), var(--bs-bg-opacity)) !important;
}

.bg-accent3 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-accent3-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-accent1-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-accent1-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white, .side-panel-modal__footer, .side-panel-modal__header {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient, .services-box:hover, .pricing-table, .btn .btn-check:checked + .btn--big-selector, .services-box__action .btn-check:checked + .btn--big-selector, .btn--big-selector {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2, .hero__heading-tooltip {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3, .timeline-step__content, .testimonial, .team-member__picture, .services-box, .product-feature, .pricing-table-calculator, .pricing-table, .card-number {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4, .team-member {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle, .timeline-step__icon {
  border-radius: 50% !important;
}

.rounded-pill, .topbar__menu-link, .topbar__box, .btn, .services-box__action {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0, .pricing-table__feature-included, .pricing-table__feature-name {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4, .side-panel-modal__header {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
.tippy-box[data-animation=fade][data-state=hidden] {
  opacity: 0;
}

[data-tippy-root] {
  max-width: calc(100vw - 10px);
}

.tippy-box {
  position: relative;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  white-space: normal;
  outline: 0;
  transition-property: transform, visibility, opacity;
}

.tippy-box[data-placement^=top] > .tippy-arrow {
  bottom: 0;
}

.tippy-box[data-placement^=top] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}

.tippy-box[data-placement^=bottom] > .tippy-arrow {
  top: 0;
}

.tippy-box[data-placement^=bottom] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}

.tippy-box[data-placement^=left] > .tippy-arrow {
  right: 0;
}

.tippy-box[data-placement^=left] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}

.tippy-box[data-placement^=right] > .tippy-arrow {
  left: 0;
}

.tippy-box[data-placement^=right] > .tippy-arrow:before {
  left: -7px;
  border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
}

.tippy-box[data-inertia][data-state=visible] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}

.tippy-arrow {
  width: 16px;
  height: 16px;
  color: #333;
}

.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}

.tippy-content {
  position: relative;
  padding: 5px 9px;
  z-index: 1;
}

.animate-in {
  animation: fade-in 0.25s cubic-bezier(0.6, -0.28, 0.735, 0.045), slide-in 0.25s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.animate-out {
  animation: fade-out 0.25s cubic-bezier(0.6, -0.28, 0.735, 0.045), slide-out 0.25s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.slide-in-right {
  animation: fade-in 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045), slide-in-right 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.tilt-shake {
  animation: tilt-shaking 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s 8;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes slide-in {
  from {
    transform: translateY(4rem);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes slide-out {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(4rem);
  }
}
@keyframes slide-in-right {
  from {
    transform: translate3d(20%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slide-in-left {
  from {
    transform: translate3d(-20%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0%, 0, 0);
  }
}
@keyframes colored-shadow {
  from {
    text-shadow: none;
  }
  to {
    text-shadow: 9px 0px 22px rgba(242, 121, 14, 0.6);
  }
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
.zoomIn {
  animation-name: zoomIn;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, -8px, 0);
  }
}
@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
.crtp-underlined {
  border-bottom: 5px solid black;
}
.crtp-underlined:after {
  display: block;
  content: " ";
  width: 100%;
  position: relative;
  z-index: -1;
  background-color: #f2790e;
}

html, body {
  height: 100%;
  min-height: 100vh;
  background-color: #f5f8ff;
}

@media (min-width: 576px) {
  body.with-sidebar,
.fixed-inside-app {
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  body.with-sidebar,
.fixed-inside-app {
    padding-left: 60px;
  }
}
@media (min-width: 992px) {
  body.with-sidebar,
.fixed-inside-app {
    padding-left: 250px;
  }
}

body.with-sidebar-collapsed {
  padding-left: 60px;
}

.container--limited {
  max-width: 1000px;
}

[data-controller=image-loader] {
  display: none;
}

[data-empty]:empty:before {
  display: block;
  content: attr(data-empty);
}

.primary-shadow-sm, .timeline-step__content {
  box-shadow: 0 0.2rem 0.2rem 0 rgba(43, 52, 128, 0.07);
}

.primary-shadow-md {
  box-shadow: 0 0.5rem 0.5rem 0 rgba(43, 52, 128, 0.02);
}

.primary-shadow-lg, .team-member__picture, .card-number--small {
  box-shadow: 0 4px 1rem 0rem rgba(43, 52, 128, 0.2);
}

.primary-shadow-xl, .pricing-table {
  box-shadow: 0 1rem 1rem 0rem rgba(43, 52, 128, 0.08);
}

.primary-shadow-xxl, .card-number, .box, .topbar__box {
  box-shadow: 0 1rem 2rem 0rem rgba(43, 52, 128, 0.1);
}

.primary-shadow-xxxl, .card-number:hover {
  box-shadow: 0 1.5rem 2.5rem 0rem rgba(43, 52, 128, 0.1);
}

.accent1-shadow-lg {
  box-shadow: 0 4px 1rem 0rem rgba(242, 121, 14, 0.5);
}

.danger-shadow-lg {
  box-shadow: 0 4px 1rem 0rem rgba(91, 11, 1, 0.5);
}

.success-shadow-lg {
  box-shadow: 0 4px 1rem 0rem rgba(4, 119, 70, 0.4);
}

.text-primary-100 {
  color: #d5d6e6;
}

.bg-primary-100 {
  background-color: #d5d6e6, var(--bs-bg-opacity);
}

.text-primary-200 {
  color: #aaaecc;
}

.bg-primary-200 {
  background-color: #aaaecc, var(--bs-bg-opacity);
}

.text-primary-300 {
  color: #8085b3;
}

.bg-primary-300 {
  background-color: #8085b3, var(--bs-bg-opacity);
}

.text-primary-400, .box__footer--light a, .box__tips {
  color: #555d99;
}

.bg-primary-400 {
  background-color: #555d99, var(--bs-bg-opacity);
}

.text-primary-500 {
  color: #2b3480;
}

.bg-primary-500 {
  background-color: #2b3480, var(--bs-bg-opacity);
}

.text-primary-600 {
  color: #222a66;
}

.bg-primary-600 {
  background-color: #222a66, var(--bs-bg-opacity);
}

.text-primary-700 {
  color: #1a1f4d;
}

.bg-primary-700 {
  background-color: #1a1f4d, var(--bs-bg-opacity);
}

.text-primary-800 {
  color: #111533;
}

.bg-primary-800 {
  background-color: #111533, var(--bs-bg-opacity);
}

.text-primary-900 {
  color: #090a1a;
}

.bg-primary-900 {
  background-color: #090a1a, var(--bs-bg-opacity);
}

.text-small-caps {
  font-variant: all-small-caps;
}

.tabular-nums {
  font-variant-numeric: tabular-nums;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-normal {
  cursor: default;
}

.highlight {
  color: #2b3480;
  display: inline-block;
  position: relative;
  z-index: 2;
}
.highlight:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  content: " ";
  width: 100%;
  height: 10px;
  z-index: 1;
  background-color: #f2790e;
}

.border-separate {
  border-collapse: separate;
}

.table-layout-fixed {
  table-layout: fixed;
}

.h-fit-content {
  height: fit-content;
}

.flex-basis-0 {
  flex-basis: 0;
}

.d-none.js-active {
  display: block !important;
}
.d-none.js-active.d-flex, .d-none.js-active.team-member, .d-none.js-active.side-panel-modal__footer, .d-none.js-active.side-panel-modal__header, .d-none.js-active.chart-box__header, .d-none.js-active.box__header, .d-none.js-active.card-number, .d-none.js-active.box, .d-none.js-active.topbar__box {
  display: flex !important;
}

[data-controller=ajax-tooltip] {
  text-decoration: underline var(--bs-gray-400) dashed;
}

.drag-handle {
  cursor: grab;
}

.app {
  padding: 1rem;
  padding-top: 6rem;
}
@media (min-width: 768px) {
  .app {
    padding: 2rem 4rem;
    padding-top: 2rem;
  }
}

.box, .topbar__box {
  margin: auto;
  background-color: white;
  border-radius: 1rem;
}
.box--row {
  flex-direction: row !important;
}
.box--no-pad {
  padding: 0 !important;
}
.box--with-pad {
  padding: 1.5rem;
}
.box__tips {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.box__footer--light {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: #f5f8ff;
  position: relative;
}
.btn:first-letter, .services-box__action:first-letter {
  text-transform: uppercase;
}
.btn--animated-icon .bi::before {
  transition: transform 0.2s cubic-bezier(0.19, 1, 0.22, 1);
}
.btn--animated-icon:hover .bi::before {
  transform: translateX(10px);
}
.btn.btn-light, .btn-light.services-box__action {
  color: #2b3480;
}
.btn.bg-accent1, .bg-accent1.services-box__action {
  color: white !important;
  border-color: transparent !important;
}
.btn.bg-accent1:hover, .bg-accent1.services-box__action:hover {
  background-color: #e4720c !important;
}
.btn.btn-outline-primary:hover, .btn-outline-primary.services-box__action:hover, .btn.btn-outline-primary:active, .btn-outline-primary.services-box__action:active, .btn.btn-outline-primary.show, .btn-outline-primary.show.services-box__action {
  color: white !important;
  border-color: #222a66;
}
.btn.btn-outline-light, .btn-outline-light.services-box__action {
  border-color: #cbd3e3;
  background-color: white;
  color: #646e86;
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-bg: white;
  --bs-btn-active-color: var(--bs-primary);
}
.btn.btn-outline-light:hover, .btn-outline-light.services-box__action:hover, .btn.btn-outline-light.show, .btn-outline-light.show.services-box__action, .btn.btn-outline-light.active, .btn-outline-light.active.services-box__action, .btn.btn-outline-light:active, .btn-outline-light.services-box__action:active {
  border-color: var(--bs-primary);
  background-color: white;
  color: var(--bs-primary);
}
.btn-discret {
  background-color: #e9ecef;
  color: #495057;
}
.btn-discret:hover {
  background-color: #dee2e6;
  border-color: transparent;
}
.btn--no-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}
.btn--big-selector {
  --bs-btn-active-bg: transparent;
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-color: var(--bs-white);
  border-radius: var(--bs-border-radius-lg) !important;
  border-color: #343a40;
  color: #343a40;
  text-align: center;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
}
.btn--big-selector i {
  display: block;
  font-size: 1.5rem;
}
.btn--big-selector-main {
  background-color: var(--bs-light);
}
.card-number {
  background: white;
  padding: 1rem 1.5rem;
  transition: transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), shadow 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card-number--h-auto {
  height: auto !important;
}
.card-number--inline {
  flex-direction: row !important;
  align-items: center;
  gap: 0.5rem;
}
.card-number:not(:last-child) {
  margin-right: 1rem;
}
.card-number__number {
  white-space: nowrap;
  font-size: 1.5rem;
  font-weight: bold;
}
.card-number__number.placeholder {
  margin-bottom: 0.5rem;
}
.card-number__label {
  font-size: 0.85rem;
  letter-spacing: 1px;
  font-variant: all-small-caps;
}
.card-number:hover {
  transform: translateY(-8px);
}
.card-number--small {
  background: transparent;
  border: 1px solid #aaaecc;
  box-shadow: none;
  padding: 0.5rem 1rem;
}
.card-number--small .card-number__number {
  font-size: 1.1rem;
}
.card-number--small:hover {
  box-shadow: none;
  transform: none;
  transition: border-color 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.card-number--small:hover.card-number--btn {
  border-color: #2b3480;
}
.card-number:hover .card-number__btn-arrow {
  transform: translateX(0);
}
.card-number--btn {
  text-decoration: none;
}
.card-number--btn .card-number__number {
  display: inline-flex;
  justify-content: space-between;
}
.card-number__btn-arrow {
  transform: translateX(-10px);
  transition: transform 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.chart-box--no-legend .apexcharts-legend {
  display: none;
}
.chart-box--clickable .apexcharts-bar-area,
.chart-box--clickable .apexcharts-pie-area {
  cursor: pointer;
}
.chart-box--clickable .apexcharts-series-scatter > .apexcharts-marker {
  cursor: pointer;
}
.chart-box--clickable .apexcharts-treemap-rect {
  cursor: zoom-in;
}

.apexcharts-text.apexcharts-yaxis-label {
  height: 75px;
}

.apexcharts-treemap-rect.inactive-hovered {
  transition: opacity 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  opacity: 0.15;
}

.chart-box-title span, .box-title span {
  display: block;
  font-variant: all-small-caps;
}
.chart-box-title span:not(:first-child), .box-title span:not(:first-child) {
  padding-left: 1.36rem;
}
.chart-box-title__resource, .box-title__resource {
  color: #2b3480;
  font-weight: bold !important;
}
.chart-box-title__resource:before, .box-title__resource:before {
  content: "\f27b";
  display: inline-flex;
  font-family: "Bootstrap-icons";
  font-size: 0.9rem;
  margin-right: 0.1rem;
}

.property {
  display: flex;
}
.property__name {
  flex-basis: 50%;
}
.property__value {
  flex-basis: 50%;
}
.property--vertical {
  flex-direction: column;
}

.side-panel__screen {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #2b3480;
  z-index: 1000;
}
.side-panel__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 2000;
  display: none;
  opacity: 0;
  animation: fade-in 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.1s forwards, slide-in-right 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s forwards;
}
.side-panel__modal--mobile-not-fullscreen {
  width: 85vw;
}
@media (min-width: 768px) {
  .side-panel__modal {
    width: 75%;
    left: unset;
    right: 0;
  }
}
@media (min-width: 992px) {
  .side-panel__modal {
    width: 70%;
    left: unset;
    right: 0;
  }
}
@media (min-width: 1200px) {
  .side-panel__modal {
    width: 50%;
    left: unset;
    right: 0;
  }
}
.side-panel__modal:before {
  content: " ";
  background-color: transparent;
  position: absolute;
  left: 0;
  width: 4px;
  height: 100%;
  cursor: ew-resize;
  z-index: 10000;
}
.side-panel--open .side-panel__modal {
  display: block;
  box-shadow: 15px 0px 20px 25px rgba(43, 52, 128, 0.13);
}
.side-panel--open .side-panel__screen {
  display: block;
}
.side-panel--left .side-panel__modal {
  animation: fade-in 0.1s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.1s forwards, slide-in-left 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.1s forwards;
}
.side-panel--full-width .side-panel__modal {
  width: calc(100vw - 60px);
}

.side-panel-modal {
  overflow-y: auto;
}
.side-panel-modal__footer {
  box-shadow: 0 -0.5rem 0.5rem 0 rgba(43, 52, 128, 0.05);
}
.topbar {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin: 2rem 0;
}
.topbar__box {
  padding: 0.5rem 0.75rem;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 90%;
  margin: auto;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
}
@media (min-width: 768px) {
  .topbar__box {
    width: 100%;
    max-width: 1100px;
  }
}
.topbar__brand {
  width: 100px;
}
.topbar__menu-trigger {
  display: block;
}
.topbar__menu-trigger .btn, .topbar__menu-trigger .services-box__action {
  font-size: 1.45rem;
}
@media (min-width: 768px) {
  .topbar__menu-trigger {
    display: none;
  }
}
.topbar__menu {
  display: none;
}
@media (min-width: 768px) {
  .topbar__menu {
    display: block;
  }
}
.topbar__menu-link {
  padding: 0.75rem 1.5rem;
  text-decoration: none;
}
.topbar-dropdown-link {
  flex-direction: column;
}
@media (min-width: 768px) {
  .topbar-dropdown-link {
    flex-direction: column;
  }
}
.topbar-dropdown-link i {
  font-size: 0.75rem;
}
.topbar-dropdown-link:hover .topbar-main-dropdown {
  visibility: visible;
  opacity: 1;
  transform: scale(1) translateY(0);
}
.topbar-main-dropdown {
  display: block;
}
@media (min-width: 768px) {
  .topbar-main-dropdown {
    margin-bottom: 0;
    display: flex;
    background-color: white;
    box-shadow: 0 4px 1rem 0 rgba(43, 52, 128, 0.2);
    border-radius: var(--bs-border-radius-lg) !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    padding-bottom: 1.5rem !important;
    padding-top: 1.5rem !important;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    flex-direction: column;
    visibility: hidden;
    top: 85%;
    position: absolute;
    margin-left: -10px;
    min-width: 400px;
    opacity: 0;
    transform: scale(0.98) translateY(2%);
    transition: visiblity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 2;
  padding-top: 10rem;
  padding-bottom: 5rem;
  margin-top: 7rem;
  color: #2b3480;
}
.footer:after {
  background: linear-gradient(to bottom left, #eaecff 1%, #f8f9fd 35%, #fff);
  box-shadow: inset 0 77px 137px -77px rgba(138, 35, 135, 0.4);
  clip-path: polygon(0 0, 100% 17%, 100% 100%, 0 100%);
  content: " ";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.hero {
  position: relative;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 2;
  padding-top: 220px;
}
.hero--small {
  min-height: 400px;
  height: 400px;
}
.hero:after {
  position: absolute;
  top: 0;
  z-index: -1;
  background: linear-gradient(to top left, #afb6ee 1%, #f8f9fd 35%, white);
  width: 100%;
  height: 100%;
  left: 0;
  content: " ";
  clip-path: polygon(0 0, 100% 0%, 100% 55%, 0% 100%);
  box-shadow: inset 0 -100px 100px -70px rgba(138, 35, 135, 0.4);
}
.hero__heading {
  position: relative;
  font-weight: normal;
  color: #2b3480;
  font-size: 1.8rem;
  margin: 0 auto 1rem auto;
  width: 90%;
}
@media (min-width: 768px) {
  .hero__heading {
    width: 50%;
    font-size: 2rem;
  }
}
.hero__heading-tooltip {
  display: block;
  background-color: #2b3480;
  padding: 0.5rem;
  color: white;
  position: absolute;
  z-index: 2;
  top: -3rem;
  right: 50%;
  font-size: 0.8rem;
  opacity: 0;
  width: 154px;
  margin-right: -77px;
  animation: pop-in 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards 1s;
}
.hero__heading-tooltip:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-color: #2b3480 transparent transparent transparent;
  border-style: solid;
  border-width: 8px;
  left: 50%;
  margin-left: -4px;
  bottom: -16px;
}
.hero__subheading {
  color: rgba(43, 52, 128, 0.8);
  font-size: 1.1rem;
  font-weight: normal;
}

@keyframes pop-in {
  from {
    opacity: 0;
    transform: translateY(8px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.cta {
  background: rgba(228, 231, 251, 0.2705882353);
  backdrop-filter: blur(4px);
  padding: 1rem 2rem;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  border-radius: var(--bs-border-radius-lg);
  margin: auto;
  flex-direction: column;
}
@media (min-width: 768px) {
  .cta {
    border-radius: var(--bs-border-radius-pill);
    flex-direction: row;
  }
}
.cta__wording {
  text-align: left;
  color: #2b3480;
  font-size: 0.95rem;
  margin-right: 0;
}
@media (min-width: 768px) {
  .cta__wording {
    margin-right: 3rem;
    max-width: 70%;
  }
}
.cta__action {
  margin-top: 1rem;
}
@media (min-width: 768px) {
  .cta__action {
    margin-top: 0;
  }
}
.cta__action a {
  width: 100%;
}

.cta-help-text {
  font-size: 0.95rem;
  position: relative;
  z-index: 3;
}

.menu {
  font-size: 1rem;
}
.menu ul {
  padding: 0;
}
.menu ul li {
  display: flex;
}
@media (min-width: 768px) {
  .menu ul {
    list-style: none;
    margin: 0;
  }
  .menu ul li {
    margin-bottom: 0 !important;
    display: inline-flex;
  }
  .menu ul li:not(:last-child) {
    margin: 0rem;
  }
  .menu ul li:last-child {
    position: static;
    bottom: unset;
  }
}

.pricing-table__product {
  display: grid;
  grid-template-columns: 40% 1fr 2fr;
  align-items: center;
}
@media (min-width: 992px) {
  .pricing-table__product {
    grid-template-columns: 40% 1fr 2fr;
  }
}
.pricing-table__header {
  height: 100%;
}
.pricing-table__row {
  display: contents;
}
.pricing-table__feature-included {
  line-height: 1rem;
  height: 100%;
}

.pricing-table-calculator {
  background-color: #2b3480;
  box-shadow: inset 0px 0px 18px 3px #272f79;
}

.product-feature {
  display: flex;
  background-color: #f5f8ff;
  position: relative;
  overflow: hidden;
  align-items: center;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .product-feature {
    flex-direction: row;
  }
}
.product-feature:last-child {
  margin-bottom: 2rem !important;
}
.product-feature:nth-child(odd) {
  margin-top: 3rem;
  --blurry-bg-color: #afb6ee;
  --blurry-bg-opacity: .4;
  --blurry-bg-transform: translate(-60%, -100%) rotate(8deg);
}
.product-feature:nth-child(even) {
  --blurry-bg-color: #8A2387;
  --blurry-bg-opacity: .08;
  --blurry-bg-transform: translate(-60%, 200%) rotate(-15deg);
}
.product-feature:before {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 50%;
  left: 0%;
  right: 0px;
  opacity: var(--blurry-bg-opacity);
  filter: blur(120px);
  transform: var(--blurry-bg-transform);
  height: 20%;
  background-color: var(--blurry-bg-color);
  border-radius: 75%;
  pointer-events: none;
}
.product-feature--small {
  flex-direction: column;
}
.product-feature--small .product-feature__content {
  padding-right: 3rem;
  padding-bottom: 2rem;
}
.product-feature__content {
  position: relative;
  color: #2b3480;
  padding: 2rem;
}
@media (min-width: 768px) {
  .product-feature__content {
    width: 33.33%;
    padding: 3rem 0 0 3rem;
    flex-direction: row;
    justify-content: center;
  }
}
.product-feature__screens {
  padding: 2rem;
  position: relative;
  z-index: 1;
  text-align: left;
}
.product-feature .card-number {
  text-align: left;
}
.product-feature .card-number__number {
  font-size: 16px;
}
.product-feature .card-number__label {
  font-size: 10px;
}
.product-feature .chart-box-title, .product-feature .box-title {
  text-align: left;
  font-size: 12px;
}
.product-feature .chart-box-title__resource, .product-feature .box-title__resource {
  font-size: 16px !important;
}
.product-feature .placeholder {
  background-color: rgba(43, 52, 128, 0.2);
  cursor: default !important;
}

/* HTML: <div class="ribbon">Your text content</div> */
.ribbon {
  font-weight: bold;
  color: #fff;
  text-align: center;
}
.ribbon__line-1 {
  font-size: 16px;
  line-height: 1.6;
}
.ribbon__line-2 {
  font-size: 28px;
}

.ribbon {
  --s: 1.8em;
  /* the ribbon size */
  --d: 2rem;
  /* the depth */
  --c: .8em;
  /* the cutout part */
  padding: 1rem calc(var(--s) + 1em) 3rem;
  line-height: 1;
  background: conic-gradient(at left var(--s) bottom var(--d), rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.5333333333) 0 37.5%, rgba(0, 0, 0, 0.2666666667) 0) 0/50% 100% no-repeat, conic-gradient(at right var(--s) bottom var(--d), rgba(0, 0, 0, 0.2666666667) 62.5%, rgba(0, 0, 0, 0.5333333333) 0 75%, rgba(0, 0, 0, 0) 0) 100%/50% 100% no-repeat;
  clip-path: polygon(0 var(--d), var(--s) var(--d), var(--s) 0, calc(100% - var(--s)) 0, calc(100% - var(--s)) var(--d), 100% var(--d), calc(100% - var(--c)) calc(50% + var(--d) / 2), 100% 100%, calc(100% - var(--s) - var(--d)) 100%, calc(100% - var(--s) - var(--d)) calc(100% - var(--d)), calc(var(--s) + var(--d)) calc(100% - var(--d)), calc(var(--s) + var(--d)) 100%, 0 100%, var(--c) calc(50% + var(--d) / 2));
  background-color: #f2790e;
  /* the main color */
  width: 100%;
}

.services {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 768px) {
  .services {
    flex-direction: row;
    justify-content: center;
  }
}
.services-box {
  text-align: left;
  width: 100%;
  text-decoration: none;
  padding: 2rem 2rem 1rem 2rem;
  background: rgba(228, 231, 251, 0.2705882353);
  backdrop-filter: blur(4px);
  transition: background-color 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
@media (min-width: 992px) {
  .services-box {
    width: 300px;
  }
}
.services-box:hover {
  background-color: #2b3480;
  color: white;
}
.services-box:hover .services-box__action {
  color: white;
  background: rgba(255, 255, 255, 0.4);
}
.services-box:first-child {
  margin-bottom: 0.5rem;
}
@media (min-width: 768px) {
  .services-box:first-child {
    margin-right: 0.5rem;
  }
}
.services-box__title {
  display: block;
  font-weight: bold;
  animation: colored-shadow 0.25s cubic-bezier(0.47, 0, 0.745, 0.715) forwards 1s;
  font-variant: all-small-caps;
  margin-bottom: 1rem;
}
.services-box__action {
  margin: 1rem 0;
  display: block;
  background-color: rgba(198, 204, 255, 0.27);
  color: #2b3480;
}
.services-box__action:hover {
  border-color: transparent;
  background-color: rgba(198, 204, 255, 0.27);
}

.svg-path-wraper {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.svg-path-wraper__path {
  stroke: #2b3480;
}

.team-member {
  background-color: transparent;
  transition: background-color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --angle-rotate: -3deg;
  margin-bottom: 3rem;
}
@media (min-width: 992px) {
  .team-member {
    flex-direction: row !important;
  }
}
.team-member:hover {
  background-color: #f5f8ff;
}
.team-member:hover .team-member__picture {
  transform: rotate(0);
}
.team-member:nth-child(even) {
  --angle-rotate: 3deg;
}
.team-member__picture {
  transition: transform 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  border: 2px solid white;
  background: #2b3480;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  background-size: auto;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 0;
  transform: rotate(var(--angle-rotate));
  height: 280px;
}
@media (min-width: 992px) {
  .team-member__picture {
    height: 220px;
  }
}

.timeline {
  width: 85%;
  margin: auto;
}
@media (min-width: 992px) {
  .timeline {
    width: 100%;
    max-width: 800px;
  }
}

.testimonial {
  background: #f5f8ff;
  margin-left: 3rem;
}

.timeline-step {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 3rem;
  padding-bottom: 1.5rem;
  border-left: 1px solid var(--bs-border-color);
  --icon-bg-color: var(--bs-gray-200);
  --icon-text-color: var(--bs-gray-600);
}
.timeline-step:hover {
  --icon-bg-color: var(--bs-primary);
  --icon-text-color: #FFF;
}
.timeline-step__icon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-color: var(--icon-bg-color);
  color: var(--icon-text-color);
  left: -1.5rem;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.timeline-step__content-header {
  padding-top: 1rem;
  margin-bottom: 1rem !important;
}
.timeline-step__content-footer {
  margin-left: 0.5rem;
  margin-top: 1rem;
  display: flex;
  gap: 8px;
}
.timeline-step__deliverables {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  min-width: 0;
}
.timeline-step__deliverables .badge {
  font-size: 0.9rem;
  text-align: left;
  line-height: 1.2;
  white-space: initial !important;
}
.timeline-step__content {
  width: 100%;
}

.trusts {
  margin-top: 5rem;
  text-align: center;
}
.trusts__title {
  font-weight: bold;
  color: #2b3480;
  font-size: 1.2rem;
  font-variant: all-small-caps;
  margin-bottom: 0.5rem;
}
.trusts__title .text-gradient, .trusts__title .hero__heading-gradient {
  animation: colored-shadow 0.25s cubic-bezier(0.47, 0, 0.745, 0.715) forwards 1s;
}
.trusts__excerpt {
  font-weight: normal;
  color: #2b3480;
  font-size: 0.9rem;
}
.trusts__logos {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0;
  justify-content: space-evenly;
  align-items: center;
  width: 90%;
  margin: auto;
  gap: 2.5rem;
}
.trusts__logo {
  flex: 1 1 0;
  min-width: 85px;
  max-width: 200px;
}
.trusts__img {
  width: 100%;
  filter: grayscale(1);
}

body,
html {
  background: white;
  height: auto;
  min-height: 100%;
}

.mw-1100, .trusts {
  max-width: 1100px !important;
}

.mw-800 {
  max-width: 800px !important;
}

.text-gradient, .hero__heading-gradient {
  background: #8A2387;
  /* fallback for old browsers */
  background: linear-gradient(to right, #F27121, #E94057, #8A2387);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}
.text-gradient--animated, .hero__heading-gradient {
  background-size: 200% 200%;
  animation: gradient-animation 5s cubic-bezier(0.47, 0, 0.745, 0.715) infinite;
}

.text-gradient-reverse, .page-title, .hero__heading-gradient-reverse {
  font-weight: bold;
  background: #8A2387;
  /* fallback for old browsers */
  background: linear-gradient(to left, #F27121, #E94057, #8A2387);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient-reverse--animated, .page-title, .hero__heading-gradient-reverse {
  background-size: 200% 200%;
  animation: gradient-animation 5s cubic-bezier(0.47, 0, 0.745, 0.715) infinite;
}

.page-title {
  font-size: 1.6rem;
  color: #2b3480;
  text-align: center;
  margin: 3rem auto;
}

.team-picture {
  width: 180px;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
